<template>
  <div class="edp-departmentDetail__square departMemberLevel bf-op">
    <div class="edp-departmentDetail__square__title">
      <div>
        {{ $t("departmentDetail.levelTitle") }}

        <span class="departMemberLevel-num">{{ memberNum }}</span>
      </div>

      <!--<ul class="departMemberLevel-change">
        <li
          v-for="(item, index) in changeList"
          :key="'changeList' + index"
          :class="{ selected: item.selected }"
          @click="listTreeChange(index)"
        >
          <img :src="item.imageDefault" alt="" />
          <img :src="item.imageSelected" alt="" />
        </li>
      </ul>-->

      <span class="departMemberLevel-change-btn" @click="listTreeChange(1)">{{
        $t("departmentDetail.seeTree")
      }}</span>
    </div>

    <!-- memberList -->
    <section
      class="departMemberLevel-memberList"
      v-show="changeListStatus == 0"
    >
      <ul class="departMemberLevel-memberList__ul">
        <li
          v-for="(item, index) in levelList"
          :key="'levelItem' + index"
          :class="{ selected: item.selected }"
          @click="levelChange(index)"
        >
          {{ index != 0 ? item.name : $t("departmentDetail.levelTabsAll") }}
          <span v-if="item.num || item.num == 0">({{ item.num }})</span>
        </li>
      </ul>

      <div class="departMemberLevel-memberList__content">
        <swiper :options="swiperOptions" :ref="'mySwiper'">
          <swiper-slide
            v-for="(itemOut, indexOut) in mySwiper_list"
            :key="'mySwiper_Level' + indexOut"
          >
            <ul>
              <li
                v-for="(item, index) in itemOut"
                :key="'pageListIn' + index"
                @click="linkDetail(item)"
              >
                <div class="departMemberLevel-memberList__image">
                  <img :src="item.photo || defaultHeadImage" alt="" />
                </div>

                <div class="departMemberLevel-memberList__detail">
                  <em :class="{ 'dml-noPower-backop': !item.isEmpower }">{{
                    $i18n.locale === "cn"
                      ? item.cname || item.ename
                      : item.ename || item.cname
                  }}</em>

                  <div :class="{ 'dml-noPower-backop': !item.isEmpower }">
                    <div
                      class="dml-levelLevel"
                      :class="
                        'dml-levelLevel--level' +
                        utils.getValueLastWord(item.empRank)
                      "
                    >
                      {{ item.empRank }}
                    </div>
                  </div>

                  <p :class="{ 'dml-noPower-backop': !item.isEmpower }">
                    {{
                      utils.formatLang(
                        $i18n.locale,
                        item.jobPositionCn,
                        item.jobPosition
                      )
                    }}
                  </p>
                </div>

                <div class="dml-noPower" v-if="!item.isEmpower">
                  {{ $t("departmentDetail.noLimitMemberList") }}
                </div>
              </li>
            </ul>
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="departMemberLevel-memberList__circle"
        id="memberDepartLevelCircle"
      ></div>
    </section>
    <!-- memberList end -->

    <!-- memberTree -->
    <!--<section
      class="departMemberLevel-memberLevel"
      v-show="changeListStatus == 1"
    >
      <depart-level-comp :list="levelTree" />
    </section>-->
    <!-- memberTree end -->

    <edp-dialog
      ref="qesDialog"
      :title="$t('departmentDetail.profileTitle')"
      class="memberFullScreen-dialog front-dialog-bg"
    >
      <div slot="control">
        <div class="memberFullScreen-dialog__control">
          <a href="javascript:void(0);" @click="percentMini">-</a>

          <em>{{ controlPercent }}%</em>

          <a href="javascript:void(0);" @click="percentAdd">+</a>
        </div>
      </div>

      <edpWaterBg percent="20%" opacity="0.6" slot="bgContent" />

      <div
        slot="content"
        class="memberFullScreen-dialog__scroll"
        ref="scrollMemberContent"
      >
        <div
          class="memberFullScreen-dialog__scroll__in"
          :style="'transform: scale(' + controlPercent / 100 + ');'"
        >
          <depart-level-comp :list="levelTree" ref="scrollMemberIn" />
        </div>
      </div>
    </edp-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import changeImage11 from "@/src/assets/department/memberlevel_list_default.png";
import changeImage12 from "@/src/assets/department/memberlevel_list_selected.png";
import changeImage21 from "@/src/assets/department/memberlevel_tree_default.png";
import changeImage22 from "@/src/assets/department/memberlevel_tree_selected.png";
import departLevelComp from "./departLevelComp.vue";
export default {
  components: { departLevelComp },
  name: "departLevel",
  props: ["info", "pageId"],
  data() {
    return {
      controlPercent: 100,
      memberNum: 0,
      changeListStatus: 0,
      changeList: [
        {
          selected: true,
          imageDefault: changeImage11,
          imageSelected: changeImage12,
        },
        {
          selected: false,
          imageDefault: changeImage21,
          imageSelected: changeImage22,
        },
      ],

      levelList: [],
      pageList: [],
      pageListFe: 0,
      empLs: "",

      ////////////////////////////

      levelTree: [],
      /////////////////////////

      mySwiper_list: [],
      swiperOptions: {
        pagination: {
          el: "#memberDepartLevelCircle",
          clickable: true,
        },
      },

      treeLevelShowStatus: false,
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  methods: {
    ...mapActions({
      departMemberList: "departmentList/departMemberList",
      departMemberList_tree: "departmentList/departMemberList_tree",
    }),
    listTreeChange(index) {
      /*this.changeListStatus = index;
      this.changeList.forEach((val, e) => {
        if (e == index) {
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });*/

      if (index == 1) {
        this.$refs.qesDialog.show();

        /*setTimeout(() => {
          if (this.$refs.scrollMemberContent && this.$refs.scrollMemberIn) {
            this.$refs.scrollMemberContent.scrollTo(
              (this.$refs.scrollMemberIn.getWidthTop() -
                this.$refs.scrollMemberContent.clientWidth) /
                2,
              0
            );
          }
        }, 100);*/
      }
    },

    async selectChangeListInit() {
      this.mySwiper_list = [];

      let params = {
        current: 1,
        size: 3000,
        departmentId: this.pageId,
        empRank: this.empLs || this.empLs == 0 ? this.empLs : "",
      };

      let res = await this.departMemberList(params);

      let resDataLs = res.data.data.records || [];

      if (resDataLs && resDataLs.length > 0) {
        this.pageListFe =
          parseInt(res.data.data.total / 15) +
          (res.data.data.total % 15 != 0 ? 1 : 0);

        for (let i = 0; i < this.pageListFe; i++) {
          this.mySwiper_list.push([]);
        }

        resDataLs.forEach((val, e) => {
          let arryNum = e / 15;

          this.mySwiper_list.forEach((val2, e2) => {
            if (arryNum < e2 + 1 && arryNum >= e2) {
              this.mySwiper_list[e2].push(val);
            }
          });
        });
      }

      this.$forceUpdate();
    },

    async levelChange(index) {
      this.empLs = index == 0 ? "" : this.levelList[index].name;

      this.levelList.forEach((val, e) => {
        e == index
          ? this.$set(val, "selected", true)
          : this.$set(val, "selected", false);
      });

      this.selectChangeListInit();
    },

    async pageInit() {
      this.levelList = [];
      this.empLs = "";

      this.info.forEach((val) => {
        this.levelList.push({
          name: val.rankName,
          num: val.rankNum,
          selected: false,
        });
      });

      this.memberNum =
        this.levelList && this.levelList.length > 0 ? this.levelList[0].num : 0;

      this.levelChange(0);

      let res = await this.departMemberList_tree(this.pageId);

      this.levelTree = res.data && res.data.data ? [res.data.data] : [];

      this.levelTree.forEach((val, e) => {
        this.$set(val, "show", true);
        this.$set(val, "topLevel", true);
      });
    },
    linkDetail(data) {
      if (data.isEmpower) {
        let sercetCd = this.utils.encryption(data.cdsid);
        this.$router.push({
          path: "/departmentDetail/memberDetail",
          query: { id: sercetCd },
        });
      }
    },
    percentMini() {
      let perNum = 10;

      if (this.controlPercent - perNum > 0) {
        this.controlPercent = this.controlPercent - perNum;
      }
    },
    percentAdd() {
      let perNum = 10;

      if (this.controlPercent + perNum <= 100) {
        this.controlPercent = this.controlPercent + perNum;
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.departMemberLevel {
  flex-shrink: 0;
}
.departMemberLevel-change {
  display: inline-flex;

  li {
    display: flex;
    width: toPad(24);
    height: toPad(24);
    margin: 0 toPad(3);
    border-radius: toPad(4);
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    img {
      display: block;
      width: toPad(18);
      height: auto;

      &:last-child {
        display: none;
      }
    }

    &.selected {
      background: #61bfb4;

      img {
        display: none;

        &:last-child {
          display: block;
        }
      }
    }
  }
}
.departMemberLevel-num {
  color: #3e886d;
}
.departMemberLevel-memberList {
  position: relative;
  width: 100%;
}
.departMemberLevel-memberList__ul {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: toPad(20);
  flex-wrap: wrap;

  li {
    display: inline-flex;
    padding: 0 toPad(16);
    font-size: toPad(12);
    height: toPad(28);
    align-items: center;
    cursor: pointer;

    span {
      transform: scale(0.83);
      opacity: 0.4;
    }

    &.selected {
      background: rgba(109, 182, 147, 0.38);
      border-radius: toPad(6);
    }
  }
}

.departMemberLevel-memberList__content {
  position: relative;
  width: 100%;
  height: toPad(410);
  overflow: hidden;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  ul {
    display: block;
    width: 100%;
    overflow: hidden;
    padding-top: toPad(19);

    li {
      position: relative;
      display: flex;
      width: 33.3%;
      height: toPad(80);
      float: left;
      padding: toPad(6) toPad(15) toPad(19) toPad(10);
      cursor: pointer;

      .dml-noPower {
        position: absolute;
        width: 90%;
        height: calc(100% - 0.12811rem);
        color: #043c27;
        background: linear-gradient(
          269.38deg,
          rgba(166, 218, 199, 0.6) 10.27%,
          rgba(255, 255, 255, 0.6) 87.28%
        );
        backdrop-filter: blur(2px);
        left: 0;
        top: 0;
        opacity: 1;
        padding: 0 toPad(10);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.4;
        font-size: toPad(12);
        border-radius: toPad(8);
        z-index: 5;
      }
      .departMemberLevel-memberList__image {
        width: toPad(55);
        height: toPad(55);
        overflow: hidden;
        flex-shrink: 0;
        border-radius: 100%;
        background: #eee;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .departMemberLevel-memberList__detail {
        flex-basis: 100%;
        padding-left: toPad(10);
        overflow: hidden;

        em {
          display: block;
          width: 100%;
          font-size: toPad(15);
          overflow: hidden;
          font-style: normal;
          padding: toPad(4) 0 toPad(2) 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 1;
        }
        .dml-levelLevel {
          display: inline-block;
          font-size: toPad(12);
          border-radius: toPad(2);
          padding: toPad(2) toPad(5);
          color: #000;
          background: #eaf3f8;
          line-height: 1;
          transform: scale(0.83);
          transform-origin: 0 0;

          &.dml-levelLevel--level2 {
            background: #76cc9e;
            color: #fff;
          }
          &.dml-levelLevel--level3 {
            background: #fac446;
            color: #fff;
          }
          &.dml-levelLevel--level4 {
            background: #8cbbe2;
            color: #fff;
          }
          &.dml-levelLevel--level5 {
            background: #71e8db;
            color: #fff;
          }
          &.dml-levelLevel--level6 {
            background: #9eb2c4;
            color: #fff;
          }
          &.dml-levelLevel--level7 {
            background: #47beab;
            color: #fff;
          }
          &.dml-levelLevel--level8 {
            background: #76cc9e;
            color: #fff;
          }
          &.dml-levelLevel--level9 {
            background: #b5a386;
            color: #fff;
          }
          &.dml-levelLevel--level10 {
            background: #89bde9;
            color: #fff;
          }
          &.dml-levelLevel--level11 {
            background: #e7c963;
            color: #fff;
          }
        }
        p {
          display: block;
          width: 100%;
          font-size: toPad(12);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 0.5);
          transform: scale(0.83);
          transform-origin: 0 0;
          line-height: 1;
        }
      }
    }
  }
}

.departMemberLevel-memberList__circle {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: toPad(12);

  .swiper-pagination-bullet {
    display: inline-block;
    width: toPad(10);
    height: toPad(10);
    overflow: hidden;
    margin: 0 toPad(5);
    border-radius: 100%;
    cursor: pointer;
    background: #d9d9d9;

    &.swiper-pagination-bullet-active {
      background: #6bb5a3;
    }
  }
}
.departMemberLevel-memberLevel {
  width: 100%;
  height: toPad(500);
  overflow: auto;
  padding-top: toPad(45);
}
.memberFullScreen-dialog {
  width: 90vw !important;
  height: 90vh !important;

  .memberFullScreen-dialog__scroll {
    position: relative;
    width: 100%;
    height: 78vh;
    padding: toPad(10) 0 toPad(20) 0;
    margin-top: toPad(10);
    overflow: auto;
  }
  .memberFullScreen-dialog__scroll__in {
    transform-origin: center center;
  }
}
.departMemberLevel-change-btn {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: toPad(24);
  font-size: toPad(12);
  padding: toPad(6) toPad(10);
  font-weight: normal;
  cursor: pointer;
}

.memberFullScreen-dialog__control {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-right: toPad(68);
  line-height: 1;

  em {
    display: inline-block;
    font-size: toPad(12);
    font-style: normal;
    padding: 0 toPad(15);
  }
  a,
  a:link,
  a:visited {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: toPad(26);
    height: toPad(20);
    font-size: toPad(16);
    cursor: pointer;
    font-size: toPad(20);
    border-radius: toPad(3);
  }

  a:active {
    background: #edf5fd;
    color: #24539b;
    border: 1px solid #24539b;
  }
}
</style>
