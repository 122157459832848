var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.info)?_c('div',{staticClass:"edp-departmentDetail__square bf-op"},[_c('div',{staticClass:"edp-departmentDetail__square__title"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.contributionTitle"))+" "),_c('el-tooltip',{staticClass:"workSituation-title__tips",attrs:{"effect":"dark","content":_vm.$t('departmentDetail.contTips'),"placement":"right","popper-class":"workSituation-title__tipsContent"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/src/assets/member/tips.png"),"alt":""}})])],1)]),_c('ul',{staticClass:"contribution-average__list"},[_c('li',{class:{
        'contribution-average__list__up': _vm.info.recEmpNumVsCompany > 0,
        'contribution-average__list__down': _vm.info.recEmpNumVsCompany < 0,
      }},[_c('em',[_vm._v(_vm._s(_vm.info.recEmpNum || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartTitle3'))}}),_c('div',{staticClass:"contribution-average__list__buttom"},[_c('div',{staticClass:"contribution-average__list__buttom__remarks"},[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.contributionChartRemarks3"))+" ")]),_c('div',{staticClass:"contribution-average__list__buttom__percent"},[_vm._v(" "+_vm._s(Math.abs(_vm.info.recEmpNumVsCompany).toFixed(1))+"% ")])])]),_c('li',{class:{
        'contribution-average__list__up': _vm.info.recEmpSuccNumVsCompany > 0,
        'contribution-average__list__down': _vm.info.recEmpSuccNumVsCompany < 0,
      }},[_c('em',[_vm._v(_vm._s(_vm.info.recEmpSuccNum || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartTitle4'))}}),_c('div',{staticClass:"contribution-average__list__buttom"},[_c('div',{staticClass:"contribution-average__list__buttom__remarks"},[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.contributionChartRemarks4"))+" ")]),_c('div',{staticClass:"contribution-average__list__buttom__percent"},[_vm._v(" "+_vm._s(Math.abs(_vm.info.recEmpSuccNumVsCompany).toFixed(1))+"% ")])])]),_c('li',{class:{
        'contribution-average__list__up': _vm.info.recBuyCarTimeVsCompany > 0,
        'contribution-average__list__down': _vm.info.recBuyCarTimeVsCompany < 0,
      }},[_c('em',[_vm._v(_vm._s(_vm.info.recBuyCarTime || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartTitle5'))}}),_c('div',{staticClass:"contribution-average__list__buttom"},[_c('div',{staticClass:"contribution-average__list__buttom__remarks"},[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.contributionChartRemarks5"))+" ")]),_c('div',{staticClass:"contribution-average__list__buttom__percent"},[_vm._v(" "+_vm._s(Math.abs(_vm.info.recBuyCarTimeVsCompany).toFixed(1))+"% ")])])]),_c('li',{class:{
        'contribution-average__list__up': _vm.info.recCarSuccTimeVsCompany > 0,
        'contribution-average__list__down': _vm.info.recCarSuccTimeVsCompany < 0,
      }},[_c('em',[_vm._v(_vm._s(_vm.info.recCarSuccTime || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartTitle6'))}}),_c('div',{staticClass:"contribution-average__list__buttom"},[_c('div',{staticClass:"contribution-average__list__buttom__remarks"},[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.contributionChartRemarks6"))+" ")]),_c('div',{staticClass:"contribution-average__list__buttom__percent"},[_vm._v(" "+_vm._s(Math.abs(_vm.info.recCarSuccTimeVsCompany).toFixed(1))+"% ")])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }