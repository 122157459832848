var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.info)?_c('div',{staticClass:"edp-departmentDetail__square bf-op",class:{ 'edp-departmentDetail__square--en': _vm.$i18n.locale === 'en' }},[_c('div',{staticClass:"edp-departmentDetail__square__title"},[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.workSituationTitle"))+" ")]),_c('div',{staticClass:"edp-departSituation__workSituation"},[_c('ul',{staticClass:"workSituation-ul workSituation-ul--border"},[_c('li',[_c('div',{staticClass:"workSituation-title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.workSituationSubTitle1'))}}),_c('el-tooltip',{staticClass:"workSituation-title__tips",attrs:{"effect":"dark","content":_vm.$t('departmentDetail.wortStatusTips1'),"placement":"right","popper-class":"workSituation-title__tipsContent"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/src/assets/member/tips.png"),"alt":""}})])],1),_c('div',{staticClass:"workSituation-content"},[_c('section',{staticClass:"workSituation-content__num"},[_vm._v(" "+_vm._s(_vm.info.averageFieldOfficeOccupancy || 0)+"% ")]),_c('section',{staticClass:"workSituation-content__detail"},[_c('label',{class:{
                'wcd-label--up': _vm.info.comparisonFieldOfficeOccupancy >= 0,
                'wcd-label--down': _vm.info.comparisonFieldOfficeOccupancy < 0,
              }},[(_vm.info.comparisonFieldOfficeOccupancy >= 0)?_c('span',{staticClass:"workSituation-content__detail__status--up"}):_vm._e(),(_vm.info.comparisonFieldOfficeOccupancy < 0)?_c('span',{staticClass:"workSituation-content__detail__status--down"}):_vm._e(),_vm._v(_vm._s(Math.abs(_vm.info.comparisonFieldOfficeOccupancy))+"%")]),_c('p',[_vm._v(_vm._s(_vm.$t("departmentDetail.workSituationSubRemarks1")))])])])]),_c('li',[_c('div',{staticClass:"workSituation-title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.workSituationSubTitle2'))}}),_c('el-tooltip',{staticClass:"workSituation-title__tips",attrs:{"effect":"dark","content":_vm.$t('departmentDetail.wortStatusTips2'),"placement":"right","popper-class":"workSituation-title__tipsContent"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/src/assets/member/tips.png"),"alt":""}})])],1),_c('div',{staticClass:"workSituation-content"},[_c('section',{staticClass:"workSituation-content__num"},[_vm._v(" "+_vm._s(_vm.info.averageOfficeHourSite || 0)),_c('span',{staticClass:"workSituation-content__num__tips"},[_vm._v(_vm._s(_vm.$t("departmentDetail.timeUnit")))])]),_c('section',{staticClass:"workSituation-content__detail"},[_c('label',{class:{
                'wcd-label--up': _vm.info.comparisonAllOfficeHourSite >= 0,
                'wcd-label--down': _vm.info.comparisonAllOfficeHourSite < 0,
              }},[(_vm.info.comparisonAllOfficeHourSite >= 0)?_c('span',{staticClass:"workSituation-content__detail__status--up"}):_vm._e(),(_vm.info.comparisonAllOfficeHourSite < 0)?_c('span',{staticClass:"workSituation-content__detail__status--down"}):_vm._e(),_vm._v(_vm._s(Math.abs(_vm.info.comparisonAllOfficeHourSite))+"%")]),_c('p',[_vm._v(_vm._s(_vm.$t("departmentDetail.workSituationSubRemarks2")))])])])])]),_c('ul',{staticClass:"workSituation-ul"},[_c('li',[_c('div',{staticClass:"workSituation-title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.workSituationSubTitle3'))}}),_c('el-tooltip',{staticClass:"workSituation-title__tips",attrs:{"effect":"dark","content":_vm.$t('departmentDetail.wortStatusTips3'),"placement":"right","popper-class":"workSituation-title__tipsContent"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/src/assets/member/tips.png"),"alt":""}})])],1),_c('div',{staticClass:"workSituation-content"},[_c('section',{staticClass:"workSituation-content__num"},[_vm._v(" "+_vm._s(_vm.info.departmentParticipantsNum || 0)+"% ")]),_c('section',{staticClass:"workSituation-content__detail"},[_c('label',{class:{
                'wcd-label--up': _vm.info.comparisonAllParticipantsNum >= 0,
                'wcd-label--down': _vm.info.comparisonAllParticipantsNum < 0,
              }},[(_vm.info.comparisonAllParticipantsNum >= 0)?_c('span',{staticClass:"workSituation-content__detail__status--up"}):_vm._e(),(_vm.info.comparisonAllParticipantsNum < 0)?_c('span',{staticClass:"workSituation-content__detail__status--down"}):_vm._e(),_vm._v(_vm._s(Math.abs(_vm.info.comparisonAllParticipantsNum))+"%")]),_c('p',[_vm._v(_vm._s(_vm.$t("departmentDetail.workSituationSubRemarks3")))])])])]),_c('li',[_c('div',{staticClass:"workSituation-title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.workSituationSubTitle4'))}}),_c('el-tooltip',{staticClass:"workSituation-title__tips",attrs:{"effect":"dark","content":_vm.$t('departmentDetail.wortStatusTips4'),"placement":"right","popper-class":"workSituation-title__tipsContent"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/src/assets/member/tips.png"),"alt":""}})])],1),_c('div',{staticClass:"workSituation-content"},[_c('section',{staticClass:"workSituation-content__num"},[_vm._v(" "+_vm._s(_vm.info.trainingNums || 0)+"% ")]),_c('section',{staticClass:"workSituation-content__detail"},[_c('label',{class:{
                'wcd-label--up': _vm.info.trainingNumsVsCompany >= 0,
                'wcd-label--down': _vm.info.trainingNumsVsCompany < 0,
              }},[(_vm.info.trainingNumsVsCompany >= 0)?_c('span',{staticClass:"workSituation-content__detail__status--up"}):_vm._e(),(_vm.info.trainingNumsVsCompany < 0)?_c('span',{staticClass:"workSituation-content__detail__status--down"}):_vm._e(),_vm._v(_vm._s(Math.abs(_vm.info.trainingNumsVsCompany))+"%")]),_c('p',[_vm._v(_vm._s(_vm.$t("departmentDetail.workSituationSubRemarks4")))])])])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }