<template>
  <div
    class="edp-departmentDetail__square bf-op"
    v-if="info"
    :class="{ 'edp-departmentDetail__square--en': $i18n.locale === 'en' }"
  >
    <div class="edp-departmentDetail__square__title">
      {{ $t("departmentDetail.workSituationTitle") }}
    </div>

    <div class="edp-departSituation__workSituation">
      <ul class="workSituation-ul workSituation-ul--border">
        <li>
          <div class="workSituation-title">
            <span v-html="$t('departmentDetail.workSituationSubTitle1')"></span>
            <el-tooltip
              class="workSituation-title__tips"
              effect="dark"
              :content="$t('departmentDetail.wortStatusTips1')"
              placement="right"
              popper-class="workSituation-title__tipsContent"
            >
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip>
          </div>

          <div class="workSituation-content">
            <section class="workSituation-content__num">
              {{ info.averageFieldOfficeOccupancy || 0 }}%
            </section>

            <section class="workSituation-content__detail">
              <label
                :class="{
                  'wcd-label--up': info.comparisonFieldOfficeOccupancy >= 0,
                  'wcd-label--down': info.comparisonFieldOfficeOccupancy < 0,
                }"
                ><span
                  class="workSituation-content__detail__status--up"
                  v-if="info.comparisonFieldOfficeOccupancy >= 0"
                ></span
                ><span
                  class="workSituation-content__detail__status--down"
                  v-if="info.comparisonFieldOfficeOccupancy < 0"
                ></span
                >{{ Math.abs(info.comparisonFieldOfficeOccupancy) }}%</label
              >
              <p>{{ $t("departmentDetail.workSituationSubRemarks1") }}</p>
            </section>
          </div>
        </li>

        <li>
          <div class="workSituation-title">
            <span v-html="$t('departmentDetail.workSituationSubTitle2')"></span>

            <el-tooltip
              class="workSituation-title__tips"
              effect="dark"
              :content="$t('departmentDetail.wortStatusTips2')"
              placement="right"
              popper-class="workSituation-title__tipsContent"
            >
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip>
          </div>

          <div class="workSituation-content">
            <section class="workSituation-content__num">
              {{ info.averageOfficeHourSite || 0
              }}<span class="workSituation-content__num__tips">{{
                $t("departmentDetail.timeUnit")
              }}</span>
            </section>

            <section class="workSituation-content__detail">
              <label
                :class="{
                  'wcd-label--up': info.comparisonAllOfficeHourSite >= 0,
                  'wcd-label--down': info.comparisonAllOfficeHourSite < 0,
                }"
                ><span
                  class="workSituation-content__detail__status--up"
                  v-if="info.comparisonAllOfficeHourSite >= 0"
                ></span
                ><span
                  class="workSituation-content__detail__status--down"
                  v-if="info.comparisonAllOfficeHourSite < 0"
                ></span
                >{{ Math.abs(info.comparisonAllOfficeHourSite) }}%</label
              >
              <p>{{ $t("departmentDetail.workSituationSubRemarks2") }}</p>
            </section>
          </div>
        </li>
      </ul>

      <ul class="workSituation-ul">
        <li>
          <div class="workSituation-title">
            <span v-html="$t('departmentDetail.workSituationSubTitle3')"></span>
            <el-tooltip
              class="workSituation-title__tips"
              effect="dark"
              :content="$t('departmentDetail.wortStatusTips3')"
              placement="right"
              popper-class="workSituation-title__tipsContent"
            >
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip>
          </div>

          <div class="workSituation-content">
            <section class="workSituation-content__num">
              {{ info.departmentParticipantsNum || 0 }}%
            </section>

            <section class="workSituation-content__detail">
              <label
                :class="{
                  'wcd-label--up': info.comparisonAllParticipantsNum >= 0,
                  'wcd-label--down': info.comparisonAllParticipantsNum < 0,
                }"
                ><span
                  class="workSituation-content__detail__status--up"
                  v-if="info.comparisonAllParticipantsNum >= 0"
                ></span
                ><span
                  class="workSituation-content__detail__status--down"
                  v-if="info.comparisonAllParticipantsNum < 0"
                ></span
                >{{ Math.abs(info.comparisonAllParticipantsNum) }}%</label
              >
              <p>{{ $t("departmentDetail.workSituationSubRemarks3") }}</p>
            </section>
          </div>
        </li>

        <li>
          <div class="workSituation-title">
            <span v-html="$t('departmentDetail.workSituationSubTitle4')"></span>
            <el-tooltip
              class="workSituation-title__tips"
              effect="dark"
              :content="$t('departmentDetail.wortStatusTips4')"
              placement="right"
              popper-class="workSituation-title__tipsContent"
            >
              <img src="@/src/assets/member/tips.png" alt="" class="tips"
            /></el-tooltip>
          </div>

          <div class="workSituation-content">
            <section class="workSituation-content__num">
              {{ info.trainingNums || 0 }}%
            </section>

            <section class="workSituation-content__detail">
              <label
                :class="{
                  'wcd-label--up': info.trainingNumsVsCompany >= 0,
                  'wcd-label--down': info.trainingNumsVsCompany < 0,
                }"
                ><span
                  class="workSituation-content__detail__status--up"
                  v-if="info.trainingNumsVsCompany >= 0"
                ></span
                ><span
                  class="workSituation-content__detail__status--down"
                  v-if="info.trainingNumsVsCompany < 0"
                ></span
                >{{ Math.abs(info.trainingNumsVsCompany) }}%</label
              >
              <p>{{ $t("departmentDetail.workSituationSubRemarks4") }}</p>
            </section>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info"],
  name: "workSituation",
  data() {
    return {
      timeCalcAverage: "5",
      timeCalcAveragePercent: "12.1%",
      timeCalcAverageStatus: "up",
    };
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-departSituation__workSituation {
  .workSituation-ul {
    display: flex;
    width: 100%;
    overflow: hidden;

    &.workSituation-ul--border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    li {
      position: relative;
      width: 50%;
      padding: toPad(20) 0;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 63%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.1);
      }

      &:nth-of-type(2n) {
        padding-left: toPad(27);

        &::after {
          display: none;
        }

        .workSituation-content {
          .workSituation-content__num {
            min-width: toPad(90);
          }
          .workSituation-content__detail {
            p {
              padding-right: 0;
            }
          }
        }
      }
    }

    .workSituation-title {
      display: flex;
      width: 100%;
      font-size: toPad(15);
      padding-bottom: toPad(15);
    }
    .workSituation-content {
      display: flex;
      width: 100%;
      align-items: center;

      .workSituation-content__num {
        min-width: toPad(120);
        font-size: toPad(40);
        font-weight: bold;
        color: #51866f;
        flex-shrink: 0;
        white-space: nowrap;
        font-family: JLREmeric;
        padding-right: toPad(10);

        .workSituation-content__num__tips {
          font-size: toPad(14);
        }
      }
      .workSituation-content__detail {
        flex-basis: 100%;
        padding-top: toPad(4);

        label {
          position: relative;
          display: block;
          width: 100%;
          padding-left: toPad(14);
          font-family: JLREmeric;

          &.wcd-label--up {
            color: #6bab8f;
          }
          &.wcd-label--down {
            color: #d69e9e;
          }
        }
        p {
          height: toPad(26);
          padding-top: toPad(2);
          padding-right: toPad(8);
          font-size: toPad(12);
          opacity: 0.5;
        }

        .workSituation-content__detail__status--up {
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          width: 0;
          height: 0;
          border-top: toPad(6) solid transparent;
          border-left: toPad(6) solid transparent;
          border-right: toPad(6) solid transparent;
          border-bottom: toPad(8) solid #6bab8f;
        }
        .workSituation-content__detail__status--down {
          position: absolute;
          left: 0;
          top: toPad(6);
          display: inline-block;
          width: 0;
          height: 0;
          border-top: toPad(8) solid #d69e9e;
          border-left: toPad(6) solid transparent;
          border-right: toPad(6) solid transparent;
          border-bottom: toPad(6) solid transparent;
        }
      }
    }
  }
}

.edp-departmentDetail__square--en {
  .edp-departSituation__workSituation {
    .workSituation-ul {
      .workSituation-content {
        margin-top: toPad(8);

        .workSituation-content__detail {
          p {
            height: toPad(36);
          }
        }
      }
    }
  }
}
</style>
