<template>
  <div class="departMemberLevel-memberLevel__tree">
    <div
      v-for="(item, index) in list"
      :key="'memberLevelItem' + index"
      class="dmTree-item"
      :ref="item.topLevel ? 'topLevel' : ''"
      v-show="item.show"
    >
      <div class="dmTree-item__line" v-if="hasParent && list.length > 1"></div>

      <div
        class="dmTree-item__lineHeng"
        :class="{
          lineFirst: index == 0,
          lineLast: index == list.length - 1,
          lineNo: list.length == 1,
        }"
        v-if="
          hasParent && (thisIndex >= 3 || (thisIndex < 3 && list.length > 1))
        "
      ></div>

      <div :class="'level' + thisIndex + 'out'" @click="openChildren(item)">
        <div class="dmTree-item__in" :class="'level' + thisIndex">
          <img :src="item.photo || defaultHeadImage" alt="" />
          <em :class="{ 'dml-noPower-backop': !item.isEmpower }">{{
            $i18n.locale === "cn"
              ? item.cname || item.ename
              : item.ename || item.cname
          }}</em>

          <div
            class="dml-levelLevel"
            :class="{ 'dml-noPower-backop': !item.isEmpower }"
          >
            {{ item.empRank }}
          </div>

          <!--<p>{{ item.jobPosition }}</p>-->

          <div class="dml-noPower" v-if="!item.isEmpower">
            {{ $t("departmentDetail.noLimitMemberTree") }}
          </div>
        </div>

        <div
          class="dml-listCircle"
          :class="{ 'dml-listCircle--show': item.ChdShow }"
          v-if="item.children && item.children.length > 0"
        >
          {{ item.ChdShow ? "" : item.children.length }}
        </div>
      </div>

      <div
        class="dmTree-item__line childrenOpc"
        v-if="item.children && item.children.length > 0"
        :class="{ selected: item.ChdShow }"
      ></div>

      <div
        class="dmTree-item__children childrenOpc"
        :class="{ selected: item.ChdShow }"
      >
        <depart-level-comp
          :list="item.children"
          :hasParent="true"
          :outIndex="thisIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "departLevelComp",
  props: ["list", "hasParent", "outIndex"],
  data() {
    return {
      thisIndex: "",
    };
  },

  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  created() {
    if (this.outIndex || this.outIndex == 0) {
      this.thisIndex = this.outIndex + 1;
    } else {
      this.thisIndex = 0;
    }
  },
  methods: {
    getWidthTop() {
      let allWidth = 0;
      this.$refs.topLevel.forEach((val) => {
        allWidth += val.clientWidth;
      });

      return allWidth;
    },
    openChildren(item) {
      if (item.children && item.children.length > 0) {
        if (!item.ChdShow) {
          this.$set(item, "ChdShow", true);

          item.children.forEach((val) => {
            this.$set(val, "show", true);
          });
        } else {
          this.$set(item, "ChdShow", false);

          item.children.forEach((val) => {
            this.$set(val, "show", false);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.departMemberLevel-memberLevel__tree {
  position: relative;
  display: flex;
  left: 50%;
  transform: translateX(-50%);

  .level0out {
    position: relative;
    width: toPad(130);
    margin: 0 auto;
    cursor: pointer;
  }
  .level1out {
    position: relative;
    margin: 0 auto;
    width: toPad(130);
    cursor: pointer;
  }
  .level2out {
    position: relative;
    margin: 0 auto;
    cursor: pointer;
  }

  .dmTree-item {
    position: relative;
    display: inline-block;
    padding: 0 toPad(4);
    margin: 0 auto;

    .dmTree-item__in {
      position: relative;
      width: toPad(118);
      background: #fff;
      border: 1px solid #d2d2d2;
      font-size: toPad(14);
      line-height: 1;
      padding: 0 0 toPad(12) 0;
      margin: 0 auto;

      .dml-levelLevel {
        display: block;
        width: 100%;
        height: toPad(15);
        font-size: toPad(14);
        line-height: 1;
        padding: 0 toPad(11);
        margin-top: toPad(2);
      }

      .dml-noPower {
        position: absolute;
        width: 100%;
        height: 100%;
        color: #043c27;
        background: linear-gradient(
          269.38deg,
          rgba(166, 218, 199, 0.6) 10.27%,
          rgba(255, 255, 255, 0.6) 87.28%
        );
        backdrop-filter: blur(2px);
        left: 0;
        top: 0;
        opacity: 1;
        padding: 0 toPad(4);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.3;
        font-size: toPad(12);
        border-radius: toPad(2);
        z-index: 5;
      }

      img {
        display: block;
        width: toPad(118);
        height: toPad(118);
        z-index: 1;
        margin: 0 auto;
      }
    }

    em {
      display: block;
      width: 100%;
      font-style: normal;
      padding: toPad(10) toPad(11) toPad(5) toPad(11);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: toPad(16);
      font-weight: 600;
    }
    p {
      display: block;
      width: 100%;
      padding: toPad(4) 0 toPad(2) 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: scale(0.6);
      font-size: toPad(14);
    }

    .dmTree-item__line {
      position: relative;
      width: 2px;
      height: toPad(28);
      overflow: hidden;
      background: #eee;
      margin: 0 auto;

      @media screen and (min-width: 1600px) {
        height: toPad(40);
      }
    }
    .dmTree-item__lineHeng {
      width: 100%;
      height: 2px;
      background: #eee;
      position: absolute;
      left: 0;
      top: 0;

      &.lineFirst {
        width: 50%;
        left: 50% !important;
      }
      &.lineLast {
        width: 50%;
        left: 0;
      }
      &.lineNo {
        display: none;
      }
    }
  }

  .treeLastLevel {
    display: block;
    width: toPad(190);

    .dmTree-item {
      width: toPad(94);
      float: left;

      .dmTree-item__lineHeng {
        display: none;
      }

      &:nth-of-type(1) {
        .dmTree-item__lineHeng {
          display: block;
        }
      }
      &:nth-of-type(2) {
        .dmTree-item__lineHeng {
          display: block;
          width: 50%;
          left: 0;
        }
      }
    }
  }
}

.dml-listCircle {
  position: relative;
  display: flex;
  width: toPad(27);
  height: toPad(27);
  background: #81c9b9;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: toPad(3);
  font-size: toPad(14);
  line-height: 1;

  &.dml-listCircle--show {
    background: #cfcfcf;

    &::after {
      display: block;
      content: "";
      width: toPad(15);
      height: toPad(3);
      background: #ffffff;
      border-radius: toPad(12);
    }
  }
}

.childrenOpc {
  opacity: 0;

  &.selected {
    opacity: 1;
    transition: opacity 1s 0.2s;
  }
}
</style>
