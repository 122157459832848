var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edp-departmentDetail__square bf-op"},[_c('div',{staticClass:"edp-departmentDetail__square__title"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.contributionTitle2_new"))+" "),_c('el-tooltip',{staticClass:"workSituation-title__tips",attrs:{"effect":"dark","content":_vm.$t('departmentDetail.contTips2'),"placement":"right","popper-class":"workSituation-title__tipsContent"}},[_c('img',{staticClass:"tips",attrs:{"src":require("@/src/assets/member/tips.png"),"alt":""}})])],1)]),_c('section',{staticClass:"contribution-average"},[_c('div',{staticClass:"contribution-average__item"},[_c('div',{staticClass:"cai-out"},[_c('section',{staticClass:"cai-chartContent"},[_vm._m(0),_c('div',{staticClass:"contribution-average__chart",attrs:{"id":"contribution_departAvg_chart1_survey"}}),_c('div',{staticClass:"contribution-average__chart__word"},[_c('em',[_vm._v(_vm._s(_vm.enpsInfo.enpsDeptParticipationRate || 0)+"%")])]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartRemarks1'))}})]),_c('section',{staticClass:"cai-detailContent"},[_c('div',{staticClass:"contribution-average__chart__title"},[_c('em',[_vm._v(_vm._s(_vm.enpsInfo.enpsDeptAvgScore || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartTitle1'))}})]),_c('div',{staticClass:"contribution-average__chart__cpNew"},[_c('em',{class:{
                up: _vm.enpsInfo.enpsDeptAvgScoreVsPrevious > 0,
                down: _vm.enpsInfo.enpsDeptAvgScoreVsPrevious < 0,
              }},[_vm._v(_vm._s(Math.abs(_vm.enpsInfo.enpsDeptAvgScoreVsPrevious) || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionCompareLast'))}})]),_c('div',{staticClass:"contribution-average__chart__cpNew"},[_c('em',{class:{
                up: _vm.enpsInfo.enpsDeptAvgScoreVsCompany > 0,
                down: _vm.enpsInfo.enpsDeptAvgScoreVsCompany < 0,
              }},[_vm._v(_vm._s(Math.abs(_vm.enpsInfo.enpsDeptAvgScoreVsCompany) || 0))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionCompareAvg'))}})])])])]),_c('div',{staticClass:"contribution-average__item largeOne largeOne-survey"},[_c('section',{staticClass:"dept-survey"},[_c('div',{staticClass:"dept-survey__title"},[_c('div',{domProps:{"innerHTML":_vm._s(
              _vm.$t('departmentDetail.contributionChartRemarks1_newChartTitle')
            )}}),_c('ul',{staticClass:"dept-survey__title__tips"},[_c('li',[_c('div',{staticClass:"dstt-cili"}),_c('em',[_vm._v(_vm._s(_vm.$t("departmentDetail.dsttDeptScore")))])]),_c('li',[_c('div',{staticClass:"dstt-cili"}),_c('em',[_vm._v(_vm._s(_vm.$t("departmentDetail.entScore")))])])])]),_c('div',{staticClass:"dept-survey__chart",attrs:{"id":"enps_dept_rateChart"}})]),_c('section',{staticClass:"dept-survey"},[_c('div',{staticClass:"dept-survey__title"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('departmentDetail.contributionChartRemarks1'))}})]),_c('div',{staticClass:"dept-survey__chart",attrs:{"id":"enps_dept_avgChart"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contribution-average__chart contribution-average__chart--in"},[_c('div',{staticClass:"contribution-average__chart--in__in",attrs:{"id":"contribution_departAvg_chart1_in_survey"}})])
}]

export { render, staticRenderFns }