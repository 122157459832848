var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"departMemberLevel-memberLevel__tree"},_vm._l((_vm.list),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:'memberLevelItem' + index,ref:item.topLevel ? 'topLevel' : '',refInFor:true,staticClass:"dmTree-item"},[(_vm.hasParent && _vm.list.length > 1)?_c('div',{staticClass:"dmTree-item__line"}):_vm._e(),(
        _vm.hasParent && (_vm.thisIndex >= 3 || (_vm.thisIndex < 3 && _vm.list.length > 1))
      )?_c('div',{staticClass:"dmTree-item__lineHeng",class:{
        lineFirst: index == 0,
        lineLast: index == _vm.list.length - 1,
        lineNo: _vm.list.length == 1,
      }}):_vm._e(),_c('div',{class:'level' + _vm.thisIndex + 'out',on:{"click":function($event){return _vm.openChildren(item)}}},[_c('div',{staticClass:"dmTree-item__in",class:'level' + _vm.thisIndex},[_c('img',{attrs:{"src":item.photo || _vm.defaultHeadImage,"alt":""}}),_c('em',{class:{ 'dml-noPower-backop': !item.isEmpower }},[_vm._v(_vm._s(_vm.$i18n.locale === "cn" ? item.cname || item.ename : item.ename || item.cname))]),_c('div',{staticClass:"dml-levelLevel",class:{ 'dml-noPower-backop': !item.isEmpower }},[_vm._v(" "+_vm._s(item.empRank)+" ")]),(!item.isEmpower)?_c('div',{staticClass:"dml-noPower"},[_vm._v(" "+_vm._s(_vm.$t("departmentDetail.noLimitMemberTree"))+" ")]):_vm._e()]),(item.children && item.children.length > 0)?_c('div',{staticClass:"dml-listCircle",class:{ 'dml-listCircle--show': item.ChdShow }},[_vm._v(" "+_vm._s(item.ChdShow ? "" : item.children.length)+" ")]):_vm._e()]),(item.children && item.children.length > 0)?_c('div',{staticClass:"dmTree-item__line childrenOpc",class:{ selected: item.ChdShow }}):_vm._e(),_c('div',{staticClass:"dmTree-item__children childrenOpc",class:{ selected: item.ChdShow }},[_c('depart-level-comp',{attrs:{"list":item.children,"hasParent":true,"outIndex":_vm.thisIndex}})],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }