<template>
  <div class="edp-departmentDetail__square bf-op">
    <div class="edp-departmentDetail__square__title">
      <div>
        {{ $t("departmentDetail.contributionTitle2_new") }}

        <el-tooltip
          class="workSituation-title__tips"
          effect="dark"
          :content="$t('departmentDetail.contTips2')"
          placement="right"
          popper-class="workSituation-title__tipsContent"
        >
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </div>
    </div>

    <section class="contribution-average">
      <!-- item1 -->
      <div class="contribution-average__item">
        <!--<div
          class="contribution-average__chart__remarks"
          v-html="$t('departmentDetail.contributionEs')"
        ></div>-->

        <div class="cai-out">
          <section class="cai-chartContent">
            <div
              class="contribution-average__chart contribution-average__chart--in"
            >
              <div
                class="contribution-average__chart--in__in"
                id="contribution_departAvg_chart1_in_survey"
              ></div>
            </div>

            <div
              class="contribution-average__chart"
              id="contribution_departAvg_chart1_survey"
            ></div>

            <div class="contribution-average__chart__word">
              <em>{{ enpsInfo.enpsDeptParticipationRate || 0 }}%</em>
            </div>

            <p v-html="$t('departmentDetail.contributionChartRemarks1')"></p>
          </section>

          <section class="cai-detailContent">
            <div class="contribution-average__chart__title">
              <em>{{ enpsInfo.enpsDeptAvgScore || 0 }}</em>
              <p v-html="$t('departmentDetail.contributionChartTitle1')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: enpsInfo.enpsDeptAvgScoreVsPrevious > 0,
                  down: enpsInfo.enpsDeptAvgScoreVsPrevious < 0,
                }"
                >{{ Math.abs(enpsInfo.enpsDeptAvgScoreVsPrevious) || 0 }}</em
              >
              <p v-html="$t('departmentDetail.contributionCompareLast')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: enpsInfo.enpsDeptAvgScoreVsCompany > 0,
                  down: enpsInfo.enpsDeptAvgScoreVsCompany < 0,
                }"
                >{{ Math.abs(enpsInfo.enpsDeptAvgScoreVsCompany) || 0 }}</em
              >
              <p v-html="$t('departmentDetail.contributionCompareAvg')"></p>
            </div>
          </section>
        </div>
      </div>
      <!-- item1 end -->

      <div class="contribution-average__item largeOne largeOne-survey">
        <section class="dept-survey">
          <div class="dept-survey__title">
            <div
              v-html="
                $t('departmentDetail.contributionChartRemarks1_newChartTitle')
              "
            ></div>

            <ul class="dept-survey__title__tips">
              <li>
                <div class="dstt-cili"></div>
                <em>{{ $t("departmentDetail.dsttDeptScore") }}</em>
              </li>

              <li>
                <div class="dstt-cili"></div>
                <em>{{ $t("departmentDetail.entScore") }}</em>
              </li>
            </ul>
          </div>

          <div class="dept-survey__chart" id="enps_dept_rateChart"></div>
        </section>

        <section class="dept-survey">
          <div class="dept-survey__title">
            <div
              v-html="$t('departmentDetail.contributionChartRemarks1')"
            ></div>
          </div>

          <div class="dept-survey__chart" id="enps_dept_avgChart"></div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "surveyResult",
  props: ["info", "enpsInfo"],
  data() {
    return {
      myChart: null,
      myChart_in: null,

      monthLabelList: [],
      enpsCompanyAverageScore_data: [],
      enpsDeptAvgScore_data: [],
      enpsDeptParticipationRate_data: [],
    };
  },
  methods: {
    pageInit() {
      if (this.info) {
        let _this = this;
        this.info.forEach((val) => {
          this.monthLabelList.push(val.enpsMonth);
          this.enpsCompanyAverageScore_data.push(val.enpsCompanyAverageScore);
          this.enpsDeptAvgScore_data.push(val.enpsDeptAvgScore);
          this.enpsDeptParticipationRate_data.push(
            val.enpsDeptParticipationRate
          );
        });

        if (this.myChart && this.myChart.id) {
          this.myChart.dispose();
        }

        if (this.myChart_in && this.myChart_in.id) {
          this.myChart_in.dispose();
        }

        var chartDom = document.getElementById(
          "contribution_departAvg_chart1_survey"
        );

        this.myChart = echarts.init(chartDom);

        var option = {
          title: [
            {
              text: "",
            },
          ],
          polar: {
            radius: [30, "100%"],
          },

          angleAxis: {
            show: false,
            max: 100,
            startAngle: 75,
          },
          radiusAxis: {
            type: "category",
            data: ["a"],
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          series: {
            type: "bar",
            barWidth: "100",
            data: [_this.enpsInfo.enpsDeptParticipationRate || 0],
            coordinateSystem: "polar",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#9FAFD1",
            },
          },
        };

        option && this.myChart.setOption(option);

        ////////////////////////////////////////////

        var chartDom_in = document.getElementById(
          "contribution_departAvg_chart1_in_survey"
        );

        this.myChart_in = echarts.init(chartDom_in);

        var option_in = {
          polar: {
            radius: [33, "100%"],
          },

          angleAxis: {
            show: false,
            max: 100,
            startAngle: 75,
          },
          radiusAxis: {
            type: "category",
            data: ["a"],
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          series: {
            type: "bar",
            barWidth: "100",
            data: [100],
            coordinateSystem: "polar",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#ededed",
            },
          },
        };

        option_in && this.myChart_in.setOption(option_in);

        /////////////////////////////////////////////////////////

        var chartDom_rateChart = document.getElementById("enps_dept_rateChart");
        var myChart_rateChart = echarts.init(chartDom_rateChart);
        var option_rateChart;

        option_rateChart = {
          color: ["#FFD36C", "#95ADD8"],
          grid: {
            top: 30,
            left: 0,
            right: 20,
            bottom: 5,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            type: "category",
            data: this.monthLabelList,
            axisTick: {
              show: false,
            },
            axisLabel: {
              //margin: 18,
              // rotate: 45,
              fontSize: 9,
            },
          },
          yAxis: {
            type: "value",
            splitNumber: 4,
            splitLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 0.2)",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: {
              //margin: 18,
              // rotate: 45,
              fontSize: 9,
            },
          },
          series: [
            {
              name: this.$t("departmentDetail.enpsCompanyAverageScore"),
              data: this.enpsCompanyAverageScore_data,
              type: "line",
              connectNulls: true,
              Symbol: "circle",
              symbolSize: 14,
              lineStyle: {
                width: 3,
              },
              label: {
                show: false,
                position: "top",
              },
              z: 4,
              itemStyle: {
                borderWidth: 10,
              },
            },
            {
              name: this.$t("departmentDetail.enpsDeptAvgScore"),
              data: this.enpsDeptAvgScore_data,
              type: "line",
              connectNulls: true,
              z: 4,
              symbolSize: 14,
              symbolOffset: [0, 3],
              lineStyle: {
                width: 3,
              },
              label: {
                show: true,
                position: "top",
                fontSize: 10,
              },
            },
          ],
        };

        option_rateChart && myChart_rateChart.setOption(option_rateChart);

        ////////////////////////////////////////////////////////

        var chartDom_rateChart2 = document.getElementById("enps_dept_avgChart");
        var myChart_rateChart2 = echarts.init(chartDom_rateChart2);
        var option_rateChart2;

        option_rateChart2 = {
          color: ["#61BFB4"],
          grid: {
            top: 30,
            left: 0,
            right: 6,
            bottom: 5,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
            valueFormatter: (val) => {
              return val ? val + "%" : "-";
            },
          },
          xAxis: {
            type: "category",
            data: this.monthLabelList,
            axisTick: {
              show: false,
            },
            axisLabel: {
              // margin: 18,
              // rotate: 45,
              fontSize: 9,
            },
          },
          yAxis: {
            type: "value",
            splitNumber: 4,
            splitLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 0.2)",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: {
              formatter: "{value}%",
              fontSize: 9,
            },
          },
          series: [
            {
              name: this.$t("departmentDetail.enpsDeptParticipationRate"),
              data: this.enpsDeptParticipationRate_data,
              type: "line",
              connectNulls: true,
              Symbol: "circle",
              symbolSize: 14,
              lineStyle: {
                width: 3,
              },
              label: {
                show: true,
                position: "top",
                formatter: "{c}%",
                fontSize: 10,
              },
              z: 4,
              itemStyle: {
                borderWidth: 10,
              },
            },
          ],
        };

        option_rateChart2 && myChart_rateChart2.setOption(option_rateChart2);

        ////////////////////////////////////////////////////////

        $(window).resize(function () {
          _this.myChart.resize();
          _this.myChart_in.resize();
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.dept-survey__title {
  display: flex;
  font-weight: bold;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .dept-survey__title__tips {
    display: inline-flex;
    padding-right: toPad(25);

    li {
      display: inline-flex;
      align-items: center;
      margin-right: toPad(15);
      font-size: toPad(12);

      .dstt-cili {
        position: relative;
        width: toPad(20);
        margin-right: toPad(3);

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background: #95add8;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &::after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          border: 2px solid #95add8;
          position: relative;
          z-index: 1;
          background: #fff;
          margin: 0 auto;
        }
      }
      em {
        font-style: normal;
        font-weight: normal;
        transform: scale(0.83);
        opacity: 0.7;
      }

      &:nth-of-type(2) {
        .dstt-cili {
          &::before {
            background: #f4ca6b;
          }

          &::after {
            border: 2px solid #f4ca6b;
          }
        }
      }
    }
  }
}
</style>
