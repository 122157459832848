<template>
  <div class="edp-departmentDetail__square bf-op" v-if="info">
    <div class="edp-departmentDetail__square__title">
      <div>
        {{ $t("departmentDetail.contributionTitle") }}

        <el-tooltip
          class="workSituation-title__tips"
          effect="dark"
          :content="$t('departmentDetail.contTips')"
          placement="right"
          popper-class="workSituation-title__tipsContent"
        >
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </div>
    </div>

    <ul class="contribution-average__list">
      <li
        :class="{
          'contribution-average__list__up': info.recEmpNumVsCompany > 0,
          'contribution-average__list__down': info.recEmpNumVsCompany < 0,
        }"
      >
        <em>{{ info.recEmpNum || 0 }}</em>
        <p v-html="$t('departmentDetail.contributionChartTitle3')"></p>

        <div class="contribution-average__list__buttom">
          <div class="contribution-average__list__buttom__remarks">
            {{ $t("departmentDetail.contributionChartRemarks3") }}
          </div>
          <div class="contribution-average__list__buttom__percent">
            {{ Math.abs(info.recEmpNumVsCompany).toFixed(1) }}%
          </div>
        </div>
      </li>

      <li
        :class="{
          'contribution-average__list__up': info.recEmpSuccNumVsCompany > 0,
          'contribution-average__list__down': info.recEmpSuccNumVsCompany < 0,
        }"
      >
        <em>{{ info.recEmpSuccNum || 0 }}</em>
        <p v-html="$t('departmentDetail.contributionChartTitle4')"></p>

        <div class="contribution-average__list__buttom">
          <div class="contribution-average__list__buttom__remarks">
            {{ $t("departmentDetail.contributionChartRemarks4") }}
          </div>
          <div class="contribution-average__list__buttom__percent">
            {{ Math.abs(info.recEmpSuccNumVsCompany).toFixed(1) }}%
          </div>
        </div>
      </li>

      <li
        :class="{
          'contribution-average__list__up': info.recBuyCarTimeVsCompany > 0,
          'contribution-average__list__down': info.recBuyCarTimeVsCompany < 0,
        }"
      >
        <em>{{ info.recBuyCarTime || 0 }}</em>
        <p v-html="$t('departmentDetail.contributionChartTitle5')"></p>

        <div class="contribution-average__list__buttom">
          <div class="contribution-average__list__buttom__remarks">
            {{ $t("departmentDetail.contributionChartRemarks5") }}
          </div>
          <div class="contribution-average__list__buttom__percent">
            {{ Math.abs(info.recBuyCarTimeVsCompany).toFixed(1) }}%
          </div>
        </div>
      </li>

      <li
        :class="{
          'contribution-average__list__up': info.recCarSuccTimeVsCompany > 0,
          'contribution-average__list__down': info.recCarSuccTimeVsCompany < 0,
        }"
      >
        <em>{{ info.recCarSuccTime || 0 }}</em>
        <p v-html="$t('departmentDetail.contributionChartTitle6')"></p>

        <div class="contribution-average__list__buttom">
          <div class="contribution-average__list__buttom__remarks">
            {{ $t("departmentDetail.contributionChartRemarks6") }}
          </div>
          <div class="contribution-average__list__buttom__percent">
            {{ Math.abs(info.recCarSuccTimeVsCompany).toFixed(1) }}%
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "contributionNew",
  props: ["info"],
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.contribution-average__list {
  display: flex;
  width: 100%;
  padding: toPad(26) 0 toPad(8) 0;

  li {
    width: 25%;
    flex-shrink: 0;
    text-align: center;

    em {
      position: relative;
      display: flex;
      width: toPad(68);
      height: toPad(68);
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      font-family: JLREmeric;
      color: #fff;
      margin: 0 auto;
      font-size: toPad(28);
      font-style: normal;
      background: linear-gradient(0deg, #e6e6e6, #e6e6e6), #f2c150;
      letter-spacing: toPad(-3);
    }

    p {
      padding-top: toPad(10);
      color: #bfbfbf;
    }
    .contribution-average__list__buttom {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-size: toPad(12);
      padding-top: toPad(10);
      flex-direction: column-reverse;
    }
    .contribution-average__list__buttom__remarks {
      color: #d9d9d9;
      transform: scale(0.75);
      white-space: nowrap;
    }
    .contribution-average__list__buttom__percent {
      display: inline-flex;
      align-items: center;
      color: #d9d9d9;
      font-family: JLREmeric;

      &::before {
        position: relative;
        display: block;
        content: "";
        width: toPad(6);
        height: toPad(2);
        background: #d9d9d9;
        border-radius: toPad(18);
        margin: 0 toPad(4) 0 toPad(4);
      }
    }

    &.contribution-average__list__up {
      em {
        background: #3e886d;
      }
      p {
        color: #000;
      }
      .contribution-average__list__buttom__remarks {
        color: #000;
      }
      .contribution-average__list__buttom__percent {
        color: #6bab8f;

        &::before {
          width: 0;
          height: 0;
          border-top: toPad(6) solid transparent;
          border-left: toPad(6) solid transparent;
          border-right: toPad(6) solid transparent;
          border-bottom: toPad(8) solid #6bab8f;
          background: transparent;
          border-radius: 0;
          top: toPad(-2);
        }
      }
    }

    &.contribution-average__list__down {
      em {
        background: #d69e9e;
      }
      p {
        color: #000;
      }
      .contribution-average__list__buttom__remarks {
        color: #000;
      }
      .contribution-average__list__buttom__percent {
        color: #d69e9e;

        &::before {
          width: 0;
          height: 0;
          border-top: toPad(8) solid #d69e9e;
          border-left: toPad(6) solid transparent;
          border-right: toPad(6) solid transparent;
          border-bottom: toPad(6) solid transparent;
          background: transparent;
          border-radius: 0;
          top: toPad(3);
        }
      }
    }
  }
}
</style>
