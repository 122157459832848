<template>
  <div class="edp-departmentDetail__square bf-op" v-if="info">
    <div class="edp-departmentDetail__square__title">
      <div>
        {{ $t("departmentDetail.contributionTitle2") }}

        <el-tooltip
          class="workSituation-title__tips"
          effect="dark"
          :content="$t('departmentDetail.contTips2')"
          placement="right"
          popper-class="workSituation-title__tipsContent"
        >
          <img src="@/src/assets/member/tips.png" alt="" class="tips"
        /></el-tooltip>
      </div>
    </div>

    <section class="contribution-average">
      <!-- item2 -->
      <div class="contribution-average__item largeOne">
        <!--<div
          class="contribution-average__chart__remarks"
          v-html="$t('departmentDetail.contributionPs')"
        ></div>-->

        <div class="cai-out">
          <section class="cai-chartContent">
            <div
              class="contribution-average__chart contribution-average__chart--in"
            >
              <div
                class="contribution-average__chart--in__in"
                id="contribution_departAvg_chart2_in"
              ></div>
            </div>

            <div
              class="contribution-average__chart"
              id="contribution_departAvg_chart2"
            ></div>

            <div class="contribution-average__chart__word">
              <em>{{ info.psDeptParticipationRate || 0 }}%</em>
            </div>

            <p v-html="$t('departmentDetail.contributionChartRemarks2')"></p>
          </section>

          <section class="cai-detailContent">
            <div class="contribution-average__chart__title chart__title--sp">
              <em>{{ info.engagementAvgScore || 0 }}%</em>
              <p v-html="$t('departmentDetail.contributionPsTitle1')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.engagementAvgScoreVsPrevious > 0,
                  down: info.engagementAvgScoreVsPrevious < 0,
                }"
                >{{ Math.abs(info.engagementAvgScoreVsPrevious) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareLast')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.engagementAvgScoreVsCompany > 0,
                  down: info.engagementAvgScoreVsCompany < 0,
                }"
                >{{ Math.abs(info.engagementAvgScoreVsCompany) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareAvg')"></p>
            </div>
          </section>

          <section class="cai-detailContent">
            <div class="contribution-average__chart__title chart__title--sp">
              <em>{{ info.perfExcellenceAvgScore || 0 }}%</em>
              <p v-html="$t('departmentDetail.contributionPsTitle2')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.perfExcellenceAvgScoreVsPrevious > 0,
                  down: info.perfExcellenceAvgScoreVsPrevious < 0,
                }"
                >{{ Math.abs(info.perfExcellenceAvgScoreVsPrevious) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareLast')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.perfExcellenceAvgScoreVsCompany > 0,
                  down: info.perfExcellenceAvgScoreVsCompany < 0,
                }"
                >{{ Math.abs(info.perfExcellenceAvgScoreVsCompany) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareAvg')"></p>
            </div>
          </section>

          <section class="cai-detailContent">
            <div class="contribution-average__chart__title chart__title--sp">
              <em>{{ info.cultureAvgScore || 0 }}%</em>
              <p v-html="$t('departmentDetail.contributionPsTitle3')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.cultureAvgScoreVsPrevious > 0,
                  down: info.cultureAvgScoreVsPrevious < 0,
                }"
                >{{ Math.abs(info.cultureAvgScoreVsPrevious) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareLast')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.cultureAvgScoreVsCompany > 0,
                  down: info.cultureAvgScoreVsCompany < 0,
                }"
                >{{ Math.abs(info.cultureAvgScoreVsCompany) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareAvg')"></p>
            </div>
          </section>

          <section class="cai-detailContent">
            <div class="contribution-average__chart__title chart__title--sp">
              <em>{{ info.myManagerAvgScore || 0 }}%</em>
              <p v-html="$t('departmentDetail.contributionPsTitle4')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.myManagerAvgScoreVsPrevious > 0,
                  down: info.myManagerAvgScoreVsPrevious < 0,
                }"
                >{{ Math.abs(info.myManagerAvgScoreVsPrevious) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareLast')"></p>
            </div>

            <div class="contribution-average__chart__cpNew">
              <em
                :class="{
                  up: info.myManagerAvgScoreVsCompany > 0,
                  down: info.myManagerAvgScoreVsCompany < 0,
                }"
                >{{ Math.abs(info.myManagerAvgScoreVsCompany) || 0 }}%</em
              >
              <p v-html="$t('departmentDetail.contributionCompareAvg')"></p>
            </div>
          </section>
        </div>
      </div>
      <!-- item2 end -->
    </section>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "contribution",
  props: ["info"],
  data() {
    return {
      myChart: null,
      myChart_in: null,
      myChart2: null,
      myChart_in2: null,
    };
  },
  methods: {
    pageInit() {
      if (this.info) {
        let _this = this;

        if (this.myChart2 && this.myChart2.id) {
          this.myChart2.dispose();
        }

        if (this.myChart_in2 && this.myChart_in2.id) {
          this.myChart_in2.dispose();
        }

        /////////////////////////////////////////////

        var chartDom2 = document.getElementById(
          "contribution_departAvg_chart2"
        );

        this.myChart2 = echarts.init(chartDom2);

        var option2 = {
          title: [
            {
              text: "",
            },
          ],
          polar: {
            radius: [30, "100%"],
          },

          angleAxis: {
            show: false,
            max: 100,
            startAngle: 75,
          },
          radiusAxis: {
            type: "category",
            data: ["a"],
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          series: {
            type: "bar",
            barWidth: "100",
            data: [_this.info.psDeptParticipationRate || 0],
            coordinateSystem: "polar",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#7bb7a2",
            },
          },
        };

        option2 && this.myChart2.setOption(option2);

        ////////////////////////////////////////////

        var chartDom_in2 = document.getElementById(
          "contribution_departAvg_chart2_in"
        );

        this.myChart_in2 = echarts.init(chartDom_in2);

        var option_in2 = {
          polar: {
            radius: [33, "100%"],
          },

          angleAxis: {
            show: false,
            max: 100,
            startAngle: 75,
          },
          radiusAxis: {
            type: "category",
            data: ["a"],
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          series: {
            type: "bar",
            barWidth: "100",
            data: [100],
            coordinateSystem: "polar",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#ededed",
            },
          },
        };

        option_in2 && this.myChart_in2.setOption(option_in2);

        $(window).resize(function () {
          _this.myChart2.resize();
          _this.myChart_in2.resize();
        });
      }
    },
  },
};
</script>
