<template>
  <section>
    <div v-show="pageDetailHide" v-if="refreshStatus">
      <edp-header-search
        :headerLeftWord="$t('departmentDetail.headerLeftWord')"
        :searchPlaceHolder="$t('departmentDetail.searchPlaceHolder')"
        noSearch="true"
      ></edp-header-search>

      <div class="topTips fac">
        <img src="@/src/assets/member/notice.png" alt="" class="tip" />
        <span class="f14 op5"> {{ $t("member.dataRange") }}</span>
      </div>

      <section class="edp-common-content edp-departmentDetail">
        <no-permit v-if="noPermitStatus" :noWord="noPermitStWord" />

        <div v-else>
          <section class="edp-departmentDetail__top bf-op">
            <div
              class="edp-departmentDetail__top__title"
              :style="
                $route.query?.bgColor
                  ? 'background: ' + $route.query.bgColor
                  : ''
              "
            >
              <img src="@/src/assets/department/depart-logo.png" alt="" />
            </div>

            <div class="edp-departmentDetail__top__remarks">
              <label>{{ $t("departmentDetail.departmentTitle") }}</label>
              <div class="edp-departmentDetail__top__p">
                {{ pageInfo.title }}

                <div class="edp-selectComp depart-selectComp-sp">
                  <section
                    class="edp-selectComp__title"
                    @click="selectChangeVisible_show"
                  >
                    {{ page_departmentId_remarks }}
                  </section>

                  <ul
                    class="edp-selectComp__content"
                    v-if="selectChangeVisible"
                  >
                    <li @click="changeDepart">
                      {{ $t("departmentDetail.changeDepart") }}
                    </li>

                    <li
                      v-for="(item, index) in departOptionList"
                      :key="'departOptionItem' + index"
                      :class="{ selected: item.selected }"
                      @click="changeDepart(item, index)"
                    >
                      {{
                        $i18n.locale === "cn"
                          ? item.departmentCName || item.departmentEName
                          : item.departmentEName || item.departmentCName
                      }}
                    </li>
                  </ul>
                </div>

                <!--<span class="edp-depart-select">
              <select @change="changeDepart" v-model="page_departmentId">
                <option :value="defaultOption">
                  {{ $t("departmentDetail.changeDepart") }}
                </option>

                <option
                  v-for="(item, index) in departOptionList"
                  :key="'departOptionItem' + index"
                  :value="item.departmentId"
                >
                  {{
                    $i18n.locale === "cn"
                      ? item.departmentCName
                      : item.departmentEName
                  }}
                </option>
              </select>
            </span>-->
              </div>
              <em>{{ pageInfo.remarks }}</em>
            </div>
          </section>

          <section class="edp-departmentDetail__middle">
            <div class="edp-departmentDetail__middle__left">
              <member-ratio ref="memberRatioRef" :info="ratioInfo" />

              <work-situation :info="workInfo" />
            </div>

            <div class="edp-departmentDetail__middle__right">
              <depart-member-level
                ref="levelRef"
                :info="levelListInfo"
                :pageId="nowIdLs"
              />

              <contribution-new :info="loyInfo" />
            </div>
          </section>

          <contribution ref="contributionRef" :info="loyInfo" />

          <surveyResult
            ref="surveyRef"
            :info="serveyInfo"
            :enpsInfo="loyInfo"
          />

          <depart-kpi ref="apiRef" :info="kpiInfo" :id="nowIdLs" />
        </div>
      </section>

      <van-overlay
        :show="subLoading"
        z-index="120"
        :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
      >
        <div class="loading-wrap-common">
          <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
            >&nbsp;</van-loading
          >
        </div>
      </van-overlay>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import Contribution from "../components/department/contribution.vue";
import ContributionNew from "../components/department/contributionNew.vue";
import DepartKpi from "../components/department/departKpi.vue";
import DepartMemberLevel from "../components/department/departMemberLevel.vue";
import memberRatio from "../components/department/memberRatio.vue";
import WorkSituation from "../components/department/workSituation.vue";
import surveyResult from "../components/department/surveyResult.vue";
import NoPermit from "../components/common/noPermit.vue";

export default {
  components: {
    memberRatio,
    WorkSituation,
    Contribution,
    DepartMemberLevel,
    DepartKpi,
    ContributionNew,
    surveyResult,
    NoPermit,
  },
  name: "departmentDetail",
  data() {
    return {
      subLoading: true,
      nowIdLs: "",
      pageInfo: {
        enName: "",
        cnName: "",
        title: "",
        remarks: "JLR China",
      },
      // defaultOption: "defaultOption",
      departOptionList: [],
      page_departmentId: "",
      page_departmentId_remarks: "",
      firstIn: true,

      ratioInfo: {},
      workInfo: {},
      loyInfo: {},
      kpiInfo: {},
      levelListInfo: {},
      serveyInfo: {},

      selectChangeVisible: false,

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,

      noPermitStatus: true,
      noPermitStWord: true,
    };
  },
  watch: {
    $route(val) {
      if (val.path.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.initNew();
        }
      }
    },
  },
  methods: {
    ...mapActions({
      departDetail: "departmentList/departDetail",
      departList: "departmentList/departList",
      departMemberRatio: "departmentList/departMemberRatio",
      departWorkInfo: "departmentList/departWorkInfo",
      departLoyalty: "departmentList/departLoyalty",
      departKpInfo: "departmentList/departKpInfo",
      departMemberNum: "departmentList/departMemberNum",
      chartDepartEnps: "departmentList/chartDepartEnps",
      deptUserRptPermitCheck: "backendRole/deptUserRptPermitCheck",
    }),
    selectChangeVisible_show(e) {
      e.stopPropagation();
      this.selectChangeVisible = true;
    },
    async pageDetail_depart() {
      this.subLoading = true;
      this.noPermitStWord = true;

      this.nowIdLs = this.firstIn
        ? this.$route.query.departmentId
        : this.page_departmentId;

      let resCheck = await this.deptUserRptPermitCheck(this.nowIdLs);

      if (resCheck.data && resCheck.data.data) {
        this.noPermitStatus = false;
      } else {
        this.noPermitStatus = true;
        this.noPermitStWord = false;
      }

      if (!this.noPermitStatus) {
        let res = await this.departDetail(this.nowIdLs);

        this.pageInfo.enName = res.data.data.departmentEName;
        this.pageInfo.cnName = res.data.data.departmentCName;
        this.pageInfo.title = res.data.data.departmentEName;
        this.pageInfo.remarks = res.data.data.org;

        let resRatio = await this.departMemberRatio(this.nowIdLs);

        this.ratioInfo = resRatio.data.data;

        let resWorkInfo = await this.departWorkInfo(this.nowIdLs);

        this.workInfo = resWorkInfo.data.data;

        let resLoy = await this.departLoyalty(this.nowIdLs);

        this.loyInfo = resLoy.data.data;

        let resKpi = await this.departKpInfo(this.nowIdLs);

        this.kpiInfo = resKpi.data.data;

        let resLevelNum = await this.departMemberNum(this.nowIdLs);

        this.levelListInfo = resLevelNum.data.data
          ? resLevelNum.data.data.departmetEmpRankDtos
          : [];

        let resServey = await this.chartDepartEnps({
          id: this.nowIdLs,
          departmentId: this.nowIdLs,
        });

        this.serveyInfo = resServey.data.data;

        setTimeout(() => {
          this.$refs.memberRatioRef.pageInit();
          this.$refs.contributionRef.pageInit();
          this.$refs.surveyRef.pageInit();
          this.$refs.apiRef.pageInit();
          this.$refs.levelRef.pageInit();
        }, 100);
      }

      this.subLoading = false;
    },
    async pageList_depart() {
      let res = await this.departList({
        current: 1,
        size: 1000,
      });

      this.departOptionList = res.data.data.records || [];

      this.departOptionList.forEach((val, e) => {
        if (val.departmentId === this.$route.query.departmentId) {
          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });
    },
    changeDepart(data, index) {
      if (!index && index != 0) {
        this.page_departmentId = "";
        this.page_departmentId_remarks = this.$t(
          "departmentDetail.changeDepart"
        );

        return false;
      }

      this.departOptionList.forEach((val, e) => {
        if (e == index) {
          this.page_departmentId_remarks =
            this.$i18n.locale === "cn"
              ? val.departmentCName
              : val.departmentEName;

          this.page_departmentId = val.departmentId;

          this.$set(val, "selected", true);
        } else {
          this.$set(val, "selected", false);
        }
      });

      this.firstIn = false;

      this.$nextTick(() => {
        this.pageDetail_depart();
      });
    },
    initNew() {
      let _this = this;
      if (!this.$route.query.departmentId) {
        this.$router.push({ name: "departmentList" });
      }
      if (this.firstIn) {
        this.page_departmentId_remarks = this.$t(
          "departmentDetail.changeDepart"
        );
      }
      this.pageDetail_depart();
      this.pageList_depart();

      this.$forceUpdate();

      $(document).on("click", function () {
        _this.selectChangeVisible = false;
      });
    },
  },

  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.initNew();
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-departmentDetail {
  padding: toPad(25) toPad(40) toPad(32) toPad(40);
  overflow: hidden;
}
.edp-departmentDetail__top {
  display: flex;
  width: 100%;
  background: #fff;
  box-shadow: 0 toPad(4) toPad(13) rgba(230, 236, 244, 0.6);
  border-radius: toPad(10);
  padding: toPad(14);
  position: relative;
  z-index: 10;

  .edp-departmentDetail__top__title {
    position: relative;
    width: toPad(93);
    height: toPad(93);
    border-radius: toPad(5);
    background: #a4aab0;
    color: #fff;
    margin-right: toPad(20);
    flex-shrink: 0;

    p {
      display: block;
      position: relative;
      z-index: 0;
      width: 100%;
      opacity: 0.12;
      font-size: toPad(18);
      line-height: 1.2;
      font-weight: 900;
      padding: toPad(2) toPad(10) 0 toPad(10);
      word-break: break-all;
      word-wrap: break-word;
    }
    em {
      display: block;
      position: absolute;
      z-index: 1;
      max-width: 100%;
      max-height: toPad(80);
      overflow: hidden;

      left: toPad(10);
      bottom: toPad(6);

      padding-right: toPad(5);
      font-style: normal;
      font-size: toPad(16);
      line-height: 1.2;
      word-break: break-all;
      word-wrap: break-word;
    }

    img {
      display: block;
      position: absolute;
      width: toPad(50);
      height: toPad(50);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .edp-departmentDetail__top__remarks {
    flex-basis: 100%;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label {
      display: block;
      width: 100%;
      font-size: toPad(16);
      line-height: toPad(22);
      flex-shrink: 0;
      font-weight: bold;
    }
    .edp-departmentDetail__top__p {
      display: flex;
      width: 100%;
      font-size: toPad(24);
      line-height: toPad(22);
      padding-top: toPad(9);
      color: #51866f;
      align-items: center;
      flex-shrink: 0;

      .edp-depart-select {
        margin-left: toPad(20);
      }
    }
    em {
      display: block;
      width: 100%;
      font-size: toPad(16);
      line-height: toPad(24);
      font-style: normal;
      padding-top: toPad(10);
      flex-shrink: 0;
    }
  }
}
.edp-departmentDetail__middle {
  display: flex;
  width: 100%;
  padding: toPad(5) 0 0 0;
}
.edp-departmentDetail__middle__left {
  width: 50%;
  padding-right: toPad(25);
  flex-shrink: 0;
}
.edp-departmentDetail__middle__right {
  width: 50%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.edp-departmentDetail__square {
  width: 100%;
  background: #fff;
  box-shadow: 0 toPad(4) toPad(13) rgba(230, 236, 244, 0.6);
  border-radius: toPad(10);
  padding: toPad(20);
  margin-top: toPad(25);
}
.edp-departmentDetail__square__title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: toPad(16);
  padding-bottom: toPad(5);
  font-weight: bold;
}
.edp-departmentDetail__square__content {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.depart-selectComp-sp {
  position: relative;
  display: inline-block;
  border-radius: toPad(31);
  background-color: rgba(217, 217, 217, 0.5);
  margin-left: toPad(40);

  .edp-selectComp__title {
    position: relative;
    z-index: 1;
    border-radius: toPad(31);
    padding: toPad(8) toPad(43) toPad(8) toPad(11);
    font-size: toPad(16);
    appearance: none;
    border: 0;
    outline: none;
    cursor: pointer;
    line-height: 1;
    background: transparent;
    color: #000;

    &::after {
      display: inline-block;
      content: "";
      position: absolute;
      z-index: 1;
      width: toPad(12);
      height: toPad(9);
      background: url("~@/src/assets/common/select-icon.png") no-repeat center
        center;
      background-size: auto 100%;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .edp-selectComp__content {
    top: toPad(38);
    width: toPad(120);
    max-height: toPad(200);
    overflow-x: hidden;
    overflow-y: auto;

    li {
      font-size: toPad(16);
      padding: toPad(7) toPad(9);
    }
  }
}

.workSituation-title__tips {
  position: relative;
  width: toPad(15);
  height: toPad(15);
  cursor: pointer;

  margin-left: toPad(10);
  top: toPad(2);
}
.workSituation-title__tipsContent {
  max-width: toPad(600);
  background: #dff2f0 !important;
  color: #000 !important;
  white-space: pre-line;
  line-height: 1.4;

  &[x-placement^="right"] {
    .popper__arrow {
      border-right-color: #dff2f0 !important;

      &::after {
        border-right-color: #dff2f0 !important;
      }
    }
  }

  &[x-placement^="left"] {
    .popper__arrow {
      border-left-color: #dff2f0 !important;

      &::after {
        border-left-color: #dff2f0 !important;
      }
    }
  }
}

// contribution and surveyResult
.contribution-average {
  display: flex;
  width: 100%;
  margin: toPad(10) 0 toPad(10) 0;

  .contribution-average__item {
    position: relative;
    width: toPad(280);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: toPad(15);

    // padding-bottom: toPad(14);

    /*&::after {
      content: "";
      position: absolute;
      width: 1px;
      height: toPad(110);
      overflow: hidden;
      background: #000;
      opacity: 0.1;
      right: 0;
      top: toPad(60);
    }*/

    &.largeOne {
      width: auto;
      flex-basis: 100%;
      flex-shrink: 1;
      margin-left: toPad(5);
      margin-top: toPad(10);

      &::after {
        display: none;
      }
      .cai-chartContent {
        margin-right: toPad(51);
      }
    }

    &.largeOne-survey {
      display: flex;

      .dept-survey {
        width: 50%;
        flex-shrink: 0;
      }
    }
  }
  .cai-out {
    display: flex;
    width: 100%;
  }
  .cai-chartContent {
    position: relative;
    width: toPad(114);
    height: toPad(104);
    flex-shrink: 0;

    p {
      text-align: center;
      padding-top: toPad(13);
      line-height: 1.4;
      font-size: toPad(12);
    }
  }
  .cai-detailContent {
    position: relative;
    flex-basis: 100%;
    overflow: hidden;
    padding-left: toPad(20);

    &.cd-long-content {
      width: toPad(220);
      flex-shrink: 0;
      flex-basis: auto;
    }
  }
  .contribution-average__chart {
    position: relative;
    width: 100%;
    height: toPad(104);
    z-index: 1;

    &.contribution-average__chart--in {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: 0;
      transform: translateX(-50%);

      .contribution-average__chart--in__in {
        width: 100%;
        height: 100%;
        transform: scale(0.95);
      }
    }
  }
}
.contribution-average__chart__title {
  width: 100%;
  color: #93abd3;

  &.chart__title--sp {
    color: #89bab0;
  }

  em {
    font-size: toPad(28);
    font-style: normal;
    font-weight: bold;
    font-family: JLREmeric;
  }
  p {
    font-size: toPad(12);
    color: #000;
    padding-top: toPad(2);
  }
}

.contribution-average__chart__remarks {
  width: 100%;
  margin-bottom: toPad(20);
  font-size: toPad(12);
  font-weight: bold;
}
.contribution-average__chart__word {
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  em {
    font-size: toPad(20);
    font-style: normal;
    font-weight: bold;
    font-family: JLREmeric;
  }
  p {
    font-size: toPad(12);
    padding-top: toPad(2);
  }
}

.contribution-average__chart__cpNew {
  width: 100%;
  font-size: toPad(12);
  padding-top: toPad(10);

  em {
    display: flex;
    width: 100%;
    font-style: normal;
    color: #bfbfbf;
    text-align: left;
    align-items: center;

    &::before {
      position: relative;
      display: block;
      content: "";
      width: toPad(6);
      height: toPad(2);
      background: #d9d9d9;
      border-radius: toPad(18);
      margin: 0 toPad(6) 0 toPad(4);
      top: toPad(-1);
    }

    &.up {
      color: #6bab8f;

      &::before {
        width: 0;
        height: 0;
        border-top: toPad(6) solid transparent;
        border-left: toPad(6) solid transparent;
        border-right: toPad(6) solid transparent;
        border-bottom: toPad(8) solid #6bab8f;
        background: transparent;
        border-radius: 0;
        top: toPad(-3);
      }
    }
    &.down {
      color: #d69e9e;

      &::before {
        width: 0;
        height: 0;
        border-top: toPad(8) solid #d69e9e;
        border-left: toPad(6) solid transparent;
        border-right: toPad(6) solid transparent;
        border-bottom: toPad(6) solid transparent;
        background: transparent;
        border-radius: 0;
        top: toPad(3);
      }
    }
  }
  p {
    color: #000;
    opacity: 0.5;
    transform: scale(0.83);
    text-align: left;
    transform-origin: 0 0;
    padding-top: toPad(1);
  }
}

.dept-survey__chart {
  width: 100%;
  height: toPad(230);
}
// contribution and surveyResult end
</style>
