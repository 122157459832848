<template>
  <div class="edp-departmentDetail__square edp-departKpi bf-op" v-if="info">
    <section class="edp-departKpi__square">
      <div class="edp-departmentDetail__square__title">
        {{ $t("departmentDetail.kpiMainTitle1") }}
      </div>

      <div class="edp-departKpi__content">
        <ul class="workSituation-ul">
          <li>
            <div class="workSituation-title">
              <span v-html="$t('departmentDetail.kpiSubTitle1')"></span>

              <el-tooltip
                class="workSituation-title__tips"
                effect="dark"
                :content="$t('departmentDetail.kipTips1')"
                placement="right"
                popper-class="workSituation-title__tipsContent"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>

            <div class="workSituation-content">
              <section class="workSituation-content__num">
                {{ info.deptAvgPerfScore || 0 }}
              </section>

              <!--<section class="workSituation-content__detail">
              <label
                :class="{
                  'wcd-label--up': info.deptAvgPerfVsCompany >= 0,
                  'wcd-label--down': info.deptAvgPerfVsCompany < 0,
                }"
                ><span
                  class="workSituation-content__detail__status--up"
                  v-if="info.deptAvgPerfVsCompany >= 0"
                ></span
                ><span
                  class="workSituation-content__detail__status--down"
                  v-if="info.deptAvgPerfVsCompany < 0"
                ></span
                >{{ Math.abs(info.deptAvgPerfVsCompany) }}%</label
              >
              <p>{{ $t("departmentDetail.kpiSubRemarks") }}</p>
            </section>-->
            </div>
          </li>

          <li>
            <div class="workSituation-title">
              <span v-html="$t('departmentDetail.kpiSubTitle2')"></span>
              <el-tooltip
                class="workSituation-title__tips"
                effect="dark"
                :content="$t('departmentDetail.kipTips2')"
                placement="right"
                popper-class="workSituation-title__tipsContent"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>

            <div class="workSituation-content">
              <section class="workSituation-content__num wordColor-red">
                {{ info.deptTurnoverRate || 0 }}%
              </section>

              <section class="workSituation-content__detail">
                <label
                  :class="{
                    'wcd-label--up': info.deptTurnoverRateVsCompany >= 0,
                    'wcd-label--down': info.deptTurnoverRateVsCompany < 0,
                  }"
                  ><span
                    class="workSituation-content__detail__status--up"
                    v-if="info.deptTurnoverRateVsCompany >= 0"
                  ></span
                  ><span
                    class="workSituation-content__detail__status--down"
                    v-if="info.deptTurnoverRateVsCompany < 0"
                  ></span
                  >{{ Math.abs(info.deptTurnoverRateVsCompany) }}%</label
                >
                <p>{{ $t("departmentDetail.kpiSubRemarks") }}</p>
              </section>
            </div>
          </li>
        </ul>

        <ul class="workSituation-ul workSituation-ul--border">
          <li>
            <div class="workSituation-title">
              <span v-html="$t('departmentDetail.kpiSubTitle3')"></span>
              <el-tooltip
                class="workSituation-title__tips"
                effect="dark"
                :content="$t('departmentDetail.kipTips3')"
                placement="right"
                popper-class="workSituation-title__tipsContent"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>

            <div class="workSituation-content">
              <section class="workSituation-content__num wordColor-blue">
                {{ info.evalRptEmpRatio || 0 }}%
              </section>

              <section class="workSituation-content__detail">
                <label
                  :class="{
                    'wcd-label--up': info.evalRptEmpRatioVsCompany >= 0,
                    'wcd-label--down': info.evalRptEmpRatioVsCompany < 0,
                  }"
                  ><span
                    class="workSituation-content__detail__status--up"
                    v-if="info.evalRptEmpRatioVsCompany >= 0"
                  ></span
                  ><span
                    class="workSituation-content__detail__status--down"
                    v-if="info.evalRptEmpRatioVsCompany < 0"
                  ></span
                  >{{ Math.abs(info.evalRptEmpRatioVsCompany) }}%</label
                >
                <p>{{ $t("departmentDetail.kpiSubRemarks") }}</p>
              </section>
            </div>
          </li>

          <li>
            <div class="workSituation-title">
              <span v-html="$t('departmentDetail.kpiSubTitle4')"></span>

              <el-tooltip
                class="workSituation-title__tips"
                effect="dark"
                :content="$t('departmentDetail.kipTips4')"
                placement="right"
                popper-class="workSituation-title__tipsContent"
              >
                <img src="@/src/assets/member/tips.png" alt="" class="tips"
              /></el-tooltip>
            </div>

            <div class="workSituation-content">
              <section class="workSituation-content__num wordColor-red">
                {{ info.undoneTrainRatio || 0 }}%
              </section>

              <section class="workSituation-content__detail">
                <label
                  :class="{
                    'wcd-label--up': info.undoneTrainRatioVsCompany >= 0,
                    'wcd-label--down': info.undoneTrainRatioVsCompany < 0,
                  }"
                  ><span
                    class="workSituation-content__detail__status--up"
                    v-if="info.undoneTrainRatioVsCompany >= 0"
                  ></span
                  ><span
                    class="workSituation-content__detail__status--down"
                    v-if="info.undoneTrainRatioVsCompany < 0"
                  ></span
                  >{{ Math.abs(info.undoneTrainRatioVsCompany) }}%</label
                >
                <p>{{ $t("departmentDetail.kpiSubRemarks") }}</p>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="edp-departKpi__square edp-departKpi__square--short">
      <div class="edp-departmentDetail__square__title">
        {{ $t("departmentDetail.kpiMainTitle2") }}
      </div>

      <section class="edp-departKpi__chart" id="departKpi__chart"></section>
    </section>

    <!-- 绩效人员 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="650px"
      top="22vh"
      custom-class="sub-dialog__departKpi"
    >
      <div slot="title">
        <div v-html="scoreTitle" class="sub-dialog__departKpi__title"></div>
      </div>

      <div class="sub-wrap-csp">
        <div
          class="member-item-csp"
          v-for="(item, i) in userList"
          :key="i"
          @click="goDetail(item)"
        >
          <div
            class="item-pic"
            :class="{ 'dml-noPower-backop': !item.isEmpower }"
          >
            <img :src="item.photo || nullImg" alt="" />

            <div
              class="item-pic__leave"
              v-if="!item.onTheJob || item.onTheJob == 0"
            >
              <div class="item-pic__leave__op"></div>

              <div
                class="item-pic__leave__content"
                v-html="$t('departmentDetail.leaveOffice')"
              ></div>
            </div>
          </div>

          <div
            class="item-text fcjsb"
            :class="{ 'dml-noPower-backop': !item.isEmpower }"
          >
            <div class="text-one">
              <span class="name-text txt-elps">{{
                utils.formatLang(lang, item.cname, item.ename)
              }}</span>
              <em v-if="item.empGenerations" class="lable"
                ><span>{{ item.empGenerations }}</span></em
              >
            </div>
            <div class="text-thr">
              {{ utils.formatLang(lang, item.jobPositionCn, item.jobPosition) }}
            </div>
            <div class="text-thr text-thr-bottom" v-if="item.onTheJob === '1'">
              <span class="c0" v-html="$t('departmentDetail.CompareTo')"></span>

              <span
                class="text-thr-bottom-icon color-dp-green2"
                v-if="item.performanceFluctuation > 0"
              >
                <i class="el-icon-caret-top color-dp-green2 f16"></i
                >{{ Math.abs(item.performanceFluctuation) }}</span
              >
              <span
                class="text-thr-bottom-icon cbf"
                v-if="item.performanceFluctuation === 0"
              >
                <span class="zeroMini"></span>
                {{ Math.abs(item.performanceFluctuation) }}</span
              >
              <span
                class="text-thr-bottom-icon cr"
                v-if="item.performanceFluctuation < 0"
                ><i class="el-icon-caret-bottom cr f16"></i>
                {{ Math.abs(item.performanceFluctuation) }}</span
              >
            </div>
          </div>
          <div class="dml-noPower" v-if="!item.isEmpower">
            <div>
              <p v-if="!item.onTheJob || item.onTheJob == 0">
                {{ $t("departmentDetail.leaveOfficeNotice") }}
              </p>
              {{ $t("departmentDetail.noLimitMemberList") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState, mapMutations, mapActions } from "vuex";
import scoreImage_sad from "@/src/assets/department/score_shock.png";
import scoreImage_normal from "@/src/assets/department/score_simle.png";
import scoreImage_smile from "@/src/assets/department/score_verysmile.png";

import scoreImage_sad_very from "@/src/assets/department/score_verysad.png";
import scoreImage_sad_normal from "@/src/assets/department/score_sad.png";

export default {
  name: "workSituation",
  props: ["info", "id"],
  data() {
    return {
      myChart: null,
      dialogVisible: false,
      userList: [],
      nullImg: require("@/src/assets/common/default.png"),
      scoreTitle: "",
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.login.lang,
    }),
  },
  methods: {
    ...mapActions({
      deptPerforDetail: "departmentList/deptPerforDetail",
    }),
    pageInit() {
      if (this.info) {
        let _this = this;

        var chartDom = document.getElementById("departKpi__chart");

        if (this.myChart && this.myChart.id) {
          this.myChart.dispose();
        }

        this.myChart = echarts.init(chartDom);

        var option = {
          grid: {
            left: 0,
            right: 0,
          },
          xAxis: {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#eee",
              },
            },

            boundaryGap: false,
            axisLabel: {
              show: true,

              color: "#000",
              fontSize: "16",
              padding: [14, 0, 0, 0],
              margin: 0,

              interval: 0,
              hideOverlap: false,

              formatter: (value, index) => {
                if (index == 1) {
                  return "{icon1| }" + " " + value;
                } else if (index == 2) {
                  return "{icon2| }" + " " + value;
                } else if (index == 3) {
                  return "{icon3| }" + " " + value;
                } else if (index == 4) {
                  return "{icon4| }" + " " + value;
                } else if (index == 5) {
                  return "{icon5| }" + " " + value;
                } else {
                  return "";
                }
              },
              rich: {
                icon1: {
                  backgroundColor: {
                    image: scoreImage_sad,
                  },
                  width: 25,
                  height: 25,
                },
                icon2: {
                  backgroundColor: {
                    image: scoreImage_sad_very,
                  },
                  width: 25,
                  height: 25,
                },
                icon3: {
                  backgroundColor: {
                    image: scoreImage_sad_normal,
                  },
                  width: 25,
                  height: 25,
                },
                icon4: {
                  backgroundColor: {
                    image: scoreImage_normal,
                  },
                  width: 25,
                  height: 25,
                },
                icon5: {
                  backgroundColor: {
                    image: scoreImage_smile,
                  },
                  width: 25,
                  height: 25,
                },
              },
            },
            data: ["0", "1", "2", "3", "4", "5", "0"],
          },
          yAxis: {
            type: "value",
            show: false,
          },
          series: [
            {
              data: [
                { value: -0.01 },
                {
                  value: _this.info.perfOnePercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#729689" },
                      { offset: 1, color: "rgba(114, 150, 137, 0.1)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfTwoPercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, [
                      { offset: 0, color: "rgba(247, 200, 91, 1)" },
                      { offset: 1, color: "rgba(247, 200, 91, 0.04)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfThreePercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, [
                      { offset: 0, color: "rgba(247, 200, 91, 1)" },
                      { offset: 1, color: "rgba(247, 200, 91, 0.04)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfFourPercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, [
                      { offset: 0, color: "rgba(247, 200, 91, 1)" },
                      { offset: 1, color: "rgba(247, 200, 91, 0.04)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfFivePercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "rgba(62, 136, 109, 1)" },
                      { offset: 1, color: "rgba(62, 136, 109, 0.1)" },
                    ]),
                  },
                },
                { value: -0.01 },
              ],
              type: "line",
              colorBy: "series",
              symbol: "none",
              smooth: true,
              lineStyle: {
                width: 5,
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 1, color: "rgba(147, 193, 203, 1)" },
                  { offset: 0.5, color: "rgba(247, 200, 91, 1)" },
                  { offset: 0, color: "rgba(62, 136, 109, 1)" },
                ]),
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  { offset: 1, color: "rgba(147, 193, 203, 0.5)" },
                  { offset: 0.5, color: "rgba(247, 200, 91, 0.5)" },
                  { offset: 0, color: "rgba(153, 203, 185, 0.5)" },
                ]),
              },
              z: 1,
            },
            {
              data: [
                { value: -0.01 },
                {
                  value: _this.info.perfOnePercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#729689" },
                      { offset: 1, color: "rgba(114, 150, 137, 0.1)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfTwoPercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, [
                      { offset: 0, color: "rgba(147, 193, 203, 1)" },
                      { offset: 1, color: "rgba(147, 193, 203, 0)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfThreePercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, [
                      { offset: 0, color: "rgba(247, 200, 91, 1)" },
                      { offset: 1, color: "rgba(247, 200, 91, 0.04)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfFourPercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0.5, 0, 1, [
                      { offset: 0, color: "rgba(120, 197, 174, 1)" },
                      { offset: 1, color: "rgba(120, 197, 174, 0)" },
                    ]),
                  },
                },
                {
                  value: _this.info.perfFivePercentage || 0,
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "rgba(62, 136, 109, 1)" },
                      { offset: 1, color: "rgba(62, 136, 109, 0.1)" },
                    ]),
                  },
                },
                { value: -0.01 },
              ],
              type: "bar",
              barWidth: "50",
              label: {
                show: true,
                formatter: ({ value }) => {
                  let result = value || 0;
                  return result >= 0 ? result + "%" : "";
                },
                position: "top",
              },
              z: 2,
            },
          ],
        };

        option && this.myChart.setOption(option);

        this.myChart.on("click", (params) => {
          this.getScoreUser(params.name);
        });

        $(window).resize(function () {
          _this.myChart.resize();
        });
      }
    },
    async getScoreUser(val) {
      let params = {
        id: this.id,
        params: {
          performanceScore: val,
        },
      };
      let res = await this.deptPerforDetail(params);
      this.userList = res.data.data;
      this.scoreTitle =
        `${this.$t("departmentDetail.scoreTitle")}` +
        `<span class="color-dp-green1">` +
        `${val}` +
        ` ${this.$t("moment.Points")}` +
        `</span>`;
      this.dialogVisible = true;
    },
    goDetail(val) {
      if (!val.isEmpower) {
        return false;
      }
      this.dialogVisible = false;
      let sercetCd = this.utils.encryption(val.cdsid);
      this.$router.push({
        path: "/memberDetailInside",
        query: { id: sercetCd },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-departKpi {
  display: flex;
  width: 100%;

  .edp-departKpi__square {
    width: 50%;

    &.edp-departKpi__square--short {
      padding: 0 toPad(20) 0 toPad(60);
    }
  }

  .edp-departKpi__content {
    padding-top: 8px;
    padding-bottom: 20px;
  }
  .workSituation-ul {
    display: flex;
    width: 100%;
    overflow: hidden;

    &.workSituation-ul--border {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    li {
      position: relative;
      width: 50%;
      padding: toPad(20) 0;
      overflow: hidden;

      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 63%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.1);
      }

      &:nth-of-type(2n) {
        padding-left: toPad(27);

        &::after {
          display: none;
        }

        .workSituation-content {
          .workSituation-content__num {
            min-width: toPad(90);
          }
          .workSituation-content__detail {
            p {
              padding-right: 0;
            }
          }
        }
      }
    }

    .workSituation-title {
      display: flex;
      width: 100%;
      font-size: toPad(15);
      padding-bottom: toPad(15);
    }
    .workSituation-content {
      display: flex;
      width: 100%;
      align-items: center;

      &.workSituation-content--disabled {
        /*-moz-animation: ;* {
          color: #999 !important;
        }
        .workSituation-content__detail__status--up {
          border-bottom-color: #999 !important;
        }
        .workSituation-content__detail__status--down {
          border-top-color: #999 !important;
        }*/
        .workSituation-content__detail p {
          color: #ccc !important;
        }
      }

      .workSituation-content__num {
        min-width: toPad(120);
        font-size: toPad(40);
        font-weight: bold;
        color: #51866f;
        flex-shrink: 0;
        white-space: nowrap;
        font-family: JLREmeric;
        padding-right: toPad(10);

        .workSituation-content__num__tips {
          font-size: toPad(14);
        }

        &.wordColor-blue {
          color: #7ea3d1;
        }
        &.wordColor-red {
          color: #e1a28e;
        }
      }
      .workSituation-content__detail {
        flex-basis: 100%;

        label {
          position: relative;
          display: block;
          width: 100%;
          padding-left: toPad(14);
          font-family: JLREmeric;

          &.wcd-label--up {
            color: #6bab8f;
          }
          &.wcd-label--down {
            color: #d69e9e;
          }
        }
        p {
          padding-top: toPad(2);
          padding-right: toPad(8);
          font-size: toPad(12);
          opacity: 0.5;
        }

        .workSituation-content__detail__status--up {
          position: absolute;
          left: 0;
          top: 0;
          display: inline-block;
          width: 0;
          height: 0;
          border-top: toPad(6) solid transparent;
          border-left: toPad(6) solid transparent;
          border-right: toPad(6) solid transparent;
          border-bottom: toPad(8) solid #6bab8f;
        }
        .workSituation-content__detail__status--down {
          position: absolute;
          left: 0;
          top: toPad(6);
          display: inline-block;
          width: 0;
          height: 0;
          border-top: toPad(8) solid #d69e9e;
          border-left: toPad(6) solid transparent;
          border-right: toPad(6) solid transparent;
          border-bottom: toPad(6) solid transparent;
        }
      }
    }
  }

  .edp-departKpi__chart {
    width: 100%;
    height: toPad(310);
  }
}
.member-item-csp {
  position: relative;
  display: flex;
  width: 48%;
  margin: toPad(10) 0 toPad(20);

  .item-pic {
    position: relative;
    width: toPad(66);
    height: toPad(66);
    border-radius: toPad(40);
    margin-right: toPad(20);

    img {
      display: block;
      position: relative;
      width: toPad(66);
      height: toPad(66);
      border-radius: 100%;
    }
    .item-pic__leave {
      width: toPad(66);
      height: toPad(66);
      border-radius: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;

      .item-pic__leave__op {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: #8a8a8a;
        opacity: 0.7;
      }
      .item-pic__leave__content {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        color: #fff;
        font-size: toPad(12);
        justify-content: center;
        align-items: center;
      }
    }
  }

  .item-text {
    width: 84%;
    .text-one {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: toPad(2);
      font-weight: 500;
      font-size: toPad(18);
      .name-text {
        display: inline-block;
        max-width: 80%;
      }
      .lable {
        display: inline-flex;
        color: #fff;
        border-radius: toPad(5) 0px;
        background: linear-gradient(90.61deg, #60cfc6 3.65%, #61bfb4 99.54%);
        padding: 0 toPad(4);
        text-align: center;
        margin-left: toPad(6);
        font-style: normal;
        border: 1px solid rgba(152, 244, 235, 0.6);
        line-height: toPad(16);

        span {
          transform: scale(0.83);
          font-size: toPad(12);
        }
      }
    }

    .text-two {
      margin-bottom: toPad(4);
      .tag {
        display: inline-block;
        padding: 0px toPad(5);
        height: toPad(18);
        line-height: toPad(18);
        margin-right: toPad(4);
        margin-bottom: toPad(4);
        background: rgba(0, 0, 0, 0.05);
        white-space: nowrap;
      }
    }

    .text-thr {
      display: flex;
      align-items: flex-start;
      margin-bottom: toPad(2);
      font-size: toPad(14);
      color: rgba(0, 0, 0, 0.5);

      &.text-thr-bottom {
        align-items: flex-end;
        font-size: toPad(11);
        .text-thr-bottom-icon {
          margin-left: toPad(10);
        }
      }

      span {
        display: inline-block;
        &.name {
          width: 17%;
        }
        &.desc {
          width: 83%;
          color: #000;
          word-break: break-all;
        }
      }
    }
  }
}
.sub-dialog__departKpi {
  border-radius: toPad(8);
  background: url("~@/src/assets/common/BG.png") no-repeat center;
  background-size: cover;

  .sub-dialog__departKpi__title {
    font-size: toPad(16);
  }

  .sub-wrap-csp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 50vh;
    padding: 0 toPad(20);
    overflow-y: auto;

    .member-item-csp {
      width: 48%;

      .item-text {
        width: 70%;
        justify-content: flex-start;
        .text-one {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: toPad(8);
          font-weight: 500;
          font-size: toPad(18);
          .name-text {
            display: inline-block;
            max-width: 70%;
          }
        }
      }
    }
  }
}

.color-dp-green1 {
  color: #3e886d;
}
.color-dp-green2 {
  color: #6bab8f;
}
.zeroMini {
  display: inline-block;
  width: toPad(6);
  height: 2px;
  overflow: hidden;
  background: rgb(191, 191, 191);
  position: relative;
  top: toPad(-3);
}
</style>
