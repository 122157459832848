<template>
  <div class="edp-departmentDetail__square bf-op" v-if="info">
    <div class="edp-departmentDetail__square__title">
      {{ $t("departmentDetail.memberRatioTitle") }}
    </div>

    <div class="edp-departmentDetail__square__content">
      <section class="memberRatio-ratio">
        <!-- square1 -->
        <div class="memberRatio-square">
          <label>{{ $t("departmentDetail.memberRatioSquareTitle1") }}</label>

          <div class="memberRatio-Gender genter--women">
            <div class="memberRatio-Gender__percent">
              <ul class="memberRatio-Gender__percent__default">
                <li
                  v-for="(item, index) in 10"
                  :key="'percentDefault' + index"
                ></li>
              </ul>

              <div
                class="memberRatio-Gender__percent__actually"
                :style="'width:' + info.girlRatio + '%'"
              >
                <ul>
                  <li
                    v-for="(item, index) in 10"
                    :key="'percentActually' + index"
                  ></li>
                </ul>
              </div>
            </div>

            <div class="memberRatio-Gender__detail">
              <em>{{ info.girlRatio }}%</em>
              <p>
                {{ $t("departmentDetail.GenderWomen") }}{{ " " + info.girlNum
                }}{{
                  $i18n.locale === "cn"
                    ? " " + $t("departmentDetail.humanUnit")
                    : ""
                }}
              </p>
            </div>
          </div>

          <div class="memberRatio-Gender genter--men">
            <div class="memberRatio-Gender__percent">
              <ul class="memberRatio-Gender__percent__default">
                <li
                  v-for="(item, index) in 10"
                  :key="'percentDefault' + index"
                ></li>
              </ul>

              <div
                class="memberRatio-Gender__percent__actually"
                :style="'width:' + info.boyRatio + '%'"
              >
                <ul>
                  <li
                    v-for="(item, index) in 10"
                    :key="'percentActually' + index"
                  ></li>
                </ul>
              </div>
            </div>

            <div class="memberRatio-Gender__detail">
              <em>{{ info.boyRatio }}%</em>
              <p>
                {{ $t("departmentDetail.GenderMen") }}{{ " " + info.boyNum
                }}{{
                  $i18n.locale === "cn"
                    ? " " + $t("departmentDetail.humanUnit")
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <!-- square1 end -->

        <!-- square2 -->
        <div class="memberRatio-square">
          <label>{{ $t("departmentDetail.areaTitle") }}</label>

          <div class="memberRatio-chinaMap">
            <div id="memberRatio_chinaMap"></div>

            <!--<div class="memberRatio-chinaMap__list">
              <div
                class="memberRatio-chinaMap__list__item"
                v-for="(item, index) in dataHuman"
                :key="'humanItem' + index"
              >
                <span v-if="index == 0">{{
                  $t("departmentDetail.shanghai")
                }}</span>
                <span v-if="index == 1">{{
                  $t("departmentDetail.beijing")
                }}</span>
                <span v-if="index == 2">{{
                  $t("departmentDetail.chengdu")
                }}</span>
                <span v-if="index == 3">{{
                  $t("departmentDetail.guangzhou")
                }}</span>
                <span v-if="index == 4">{{
                  $t("departmentDetail.otherCity")
                }}</span
                >{{ ":" + item.value + $t("departmentDetail.humanUnit") }}
              </div>
            </div>-->
          </div>
        </div>
        <!-- square2 end -->
      </section>

      <section class="memberRatio-ratio">
        <!-- square3 -->
        <div class="memberRatio-square">
          <label>{{ $t("departmentDetail.interestTitle") }}</label>

          <ul class="memberRatio-interest">
            <li
              v-for="(item, index) in interestList"
              :key="'interestItem' + index"
              class="interestStyle2"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <!-- square3 end -->

        <!-- square4 -->
        <div class="memberRatio-square">
          <div class="memberRatio-average">
            <section class="memberRatio-average__item">
              <div class="memberRatio-average__word">
                <em>{{ info.jlrAverageWorkYear }}</em
                >{{ $t("departmentDetail.averageUnitTitle1") }}
              </div>

              <div
                class="memberRatio-average__word"
                v-html="$t('departmentDetail.averageRemarks1')"
              ></div>
            </section>

            <section class="memberRatio-average__item">
              <div class="memberRatio-average__word">
                <em>{{ info.averageAge }}</em
                >{{ $t("departmentDetail.averageUnitTitle2") }}
              </div>

              <div
                class="memberRatio-average__word"
                v-html="$t('departmentDetail.averageRemarks2')"
              ></div>
            </section>
          </div>
        </div>
        <!-- square4 end -->
      </section>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: ["info"],
  name: "memberRatio",
  data() {
    return {
      dataHuman: [
        {
          name: "",
          value: 0,
          itemStyle: { areaColor: "#F7C85B", color: "#F7C85B" },
        },
        {
          name: "",
          value: 0,
          itemStyle: { areaColor: "#90C0AF", color: "#90C0AF" },
        },
        {
          name: "",
          value: 0,
          itemStyle: { areaColor: "#6BB5A3", color: "#6BB5A3" },
        },
        {
          name: "",
          value: 0,
          itemStyle: { areaColor: "#6BA0AB", color: "#6BA0AB" },
        },
        {
          name: "",
          value: 0,
          itemStyle: { areaColor: "#CAAD7F", color: "#CAAD7F" },
        },
      ],
      interestList: [],
      myChart: null,
    };
  },
  methods: {
    randomInterest_bgColor() {
      let randomNum = parseInt(Math.random() * 100);

      if (randomNum >= 50) {
        return 2;
      } else {
        return 1;
      }
    },
    pageInit() {
      let _this = this;
      this.interestList = [];

      let listLs = [];

      if (this.$i18n.locale === "cn") {
        listLs =
          this.info.hobbyInterestCns && this.info.hobbyInterestCns.length > 0
            ? this.info.hobbyInterestCns || []
            : this.info.hobbyInterests || [];
      } else {
        listLs =
          this.info.hobbyInterests && this.info.hobbyInterests.length > 0
            ? this.info.hobbyInterests || []
            : this.info.hobbyInterestCns || [];
      }

      listLs.forEach((val, e) => {
        this.interestList.push({
          name: val,
          bgStyle: this.randomInterest_bgColor(),
        });
      });

      /* map */

      let dataHumanLabel = [];

      this.dataHuman.forEach((val, e) => {
        if (e == 0) {
          val.value = this.info.shanghaiHeadcount || 0;
          val.name = this.$t("departmentDetail.shanghai");
        }
        if (e == 1) {
          val.value = this.info.beijingHeadcount || 0;
          val.name = this.$t("departmentDetail.beijing");
        }
        if (e == 2) {
          val.value = this.info.chengduHeadcount || 0;
          val.name = this.$t("departmentDetail.chengdu");
        }
        if (e == 3) {
          val.value = this.info.guangzhouHeadcount || 0;
          val.name = this.$t("departmentDetail.guangzhou");
        }
        if (e == 4) {
          val.value = this.info.otherCityHeadcount || 0;
          val.name = this.$t("departmentDetail.otherCity");
        }

        dataHumanLabel.push(val.name);
      });

      var option = {
        grid: {
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
        title: {
          text: "",
          subtext: "",
          sublink: "",
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            show: true,

            color: "#000",
            fontSize: _this.$i18n.locale === "cn" ? "10" : "9",
            interval: 0,
            hideOverlap: false,
          },
          data: dataHumanLabel,
        },
        yAxis: {
          type: "value",
          show: false,
        },

        series: [
          {
            type: "bar",
            barWidth: "22",
            label: {
              show: true,
              position: "top",
            },

            itemStyle: {
              areaColor: "white",
            },
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },

            data: _this.dataHuman,
          },
        ],
      };

      var chartDom = document.getElementById("memberRatio_chinaMap");

      if (this.myChart && this.myChart.id) {
        this.myChart.dispose();
      }

      this.myChart = echarts.init(chartDom);

      option && this.myChart.setOption(option);

      $(window).resize(function () {
        _this.myChart.resize();
      });

      /* map end */
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.memberRatio-ratio {
  width: 100%;
  overflow: hidden;
  padding-top: toPad(9);

  &:first-child {
    padding-top: toPad(14);
  }

  .memberRatio-square {
    width: 50%;
    float: left;

    &:nth-of-type(2n) {
      padding-left: toPad(38);
    }

    label {
      display: block;
      width: 100%;
      font-size: toPad(14);
      padding-bottom: toPad(5);
    }
    .memberRatio-Gender {
      display: flex;
      width: 100%;
      align-items: center;

      /* gender in */
      .memberRatio-Gender__percent {
        position: relative;
        width: toPad(190);
        flex-shrink: 0;

        .memberRatio-Gender__percent__default {
          display: flex;
          width: toPad(190);
          position: relative;
          z-index: 0;

          li {
            flex-grow: 1;
            flex-shrink: 0;
            height: toPad(32);
          }
        }
        .memberRatio-Gender__percent__actually {
          position: absolute;
          width: 100%;
          z-index: 1;
          left: 0;
          top: 0;
          overflow: hidden;

          ul {
            display: flex;
            width: toPad(190);

            li {
              flex-grow: 1;
              flex-shrink: 0;
              height: toPad(32);
            }
          }
        }
      }

      .memberRatio-Gender__detail {
        flex-basis: 100%;
        padding: toPad(6) 0 toPad(6) 0;
        text-align: center;

        em {
          font-size: toPad(24);
          font-style: normal;
          font-weight: bold;
        }

        p {
          font-size: toPad(12);
          padding-top: toPad(4);
          text-align: center;
          word-break: break-all;
          word-wrap: break-word;
        }
      }

      &.genter--women {
        .memberRatio-Gender__percent {
          .memberRatio-Gender__percent__default {
            li {
              background: url("~@/src/assets/department/people-default-women.png")
                no-repeat center center;
              background-size: auto 100%;
            }
          }

          .memberRatio-Gender__percent__actually {
            ul {
              li {
                background: url("~@/src/assets/department/people-women.png")
                  no-repeat center center;
                background-size: auto 100%;
              }
            }
          }
        }
        .memberRatio-Gender__detail {
          em {
            color: #f16078;
          }
        }
      }

      &.genter--men {
        .memberRatio-Gender__percent {
          .memberRatio-Gender__percent__default {
            li {
              background: url("~@/src/assets/department/people-default-men.png")
                no-repeat center center;
              background-size: auto 100%;
            }
          }
          .memberRatio-Gender__percent__actually {
            ul {
              li {
                background: url("~@/src/assets/department/people-men.png")
                  no-repeat center center;
                background-size: auto 100%;
              }
            }
          }
        }
        .memberRatio-Gender__detail {
          em {
            color: #61bfb4;
          }
        }
      }
      /* gender in end */
    }
  }
}

.memberRatio-chinaMap {
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: toPad(6);
}
#memberRatio_chinaMap {
  position: relative;
  width: 100%;
  height: toPad(134);
  z-index: 1;
}
.memberRatio-chinaMap__list {
  position: absolute;
  width: 100%;
  z-index: 0;
  left: toPad(10);
  top: 50%;
  transform: translateY(-50%);

  .memberRatio-chinaMap__list__item {
    width: 100%;
    padding: toPad(5) 0 0 0;
    font-size: toPad(12);
    transform: scale(0.8);
    transform-origin: 0 center;
  }
}
.memberRatio-interest {
  display: flex;
  width: 100%;
  height: toPad(119);
  overflow-x: hidden;
  overflow-y: auto;
  margin: toPad(12) 0 0 0;
  flex-wrap: wrap;
  justify-content: center;

  li {
    display: inline-block;
    flex-shrink: 0;
    border: 1px solid #ddd;
    border-radius: toPad(26);
    padding: toPad(6) toPad(18);
    margin: toPad(8) toPad(4) 0 toPad(4);
    font-size: toPad(12);
    background: #fff;
    color: #000;

    &.interestStyle2 {
      border: 1px solid #caad7f;
    }
    &.interestStyle3 {
      color: #fff;
      border: 1px solid #caad7f;
      background: #caad7f;
    }
  }
}

.memberRatio-average {
  display: flex;
  width: 100%;
  height: toPad(188);
  justify-content: center;
  align-items: center;

  .memberRatio-average__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: toPad(110);
    height: toPad(110);
    border-radius: 100%;
    background: #6bb5a3;
    opacity: 0.9;
    flex-shrink: 0;
    font-size: toPad(12);
    top: toPad(-10);
    left: toPad(6);
    z-index: 1;

    em {
      position: relative;
      font-size: toPad(36);
      font-weight: bold;
      font-style: normal;
      line-height: 1;
      margin: 0 toPad(2) 0 0;
      top: toPad(2);
      font-family: JLREmeric;
    }

    &:last-child {
      background: #f7c85b;
      top: toPad(10);
      left: toPad(-6);
      z-index: 0;
    }
  }

  .memberRatio-average__word {
    position: relative;
    display: flex;
    width: 100%;
    line-height: 1.4;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    text-align: center;
    padding-bottom: toPad(2);
  }
}
</style>
